@import '../settings/_settings';

.custom_radio_cont {
    padding: 15px 0;

    .custom_radio_title {
        font-family: $Inter;
        font-weight: 400;

        &.small {
            font-size: 12px;
            line-height: 15px;
        }

        &.medium {
            font-size: 14px;
            line-height: 17px;
        }

        &.large {
            font-size: 16px;
            line-height: 19px;
        }
    }

    .custom_radio_label {
        display: flex;
        align-items: center;
        cursor: pointer;
        font-family: $Inter;
        font-weight: 400;
        margin: 5px 0;

        input[type="radio"] {
            opacity: 0;
            position: absolute;

            &:checked+.custom_radio_circle {
                background-color: transparent;
                border-color: $primary-btn-color;

                &:after {
                    background-color: $primary-btn-color;
                    opacity: 1;
                }
            }

            &:disabled+.custom_radio_circle {
                border-color: $primary-btn-disabled-color;
                cursor: not-allowed !important;

                &:after {
                    background-color: $primary-btn-disabled-color;
                    cursor: not-allowed !important;
                }
            }
        }

        .custom_radio_circle {
            border: 2px solid $primary-btn-color;
            border-radius: 50%;
            display: inline-block;
            margin-right: 8px;
            position: relative;
            transition: background-color 0.2s, border-color 0.2s;

            &:after {
                content: "";
                background-color: transparent;
                border-radius: 50%;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                opacity: 0;
                transition: opacity 0.2s;
            }

            &.small {
                width: 12px;
                height: 12px;

                &:after {
                    width: 6px;
                    height: 6px;
                }
            }

            &.medium {
                width: 14px;
                height: 14px;

                &:after {
                    width: 7px;
                    height: 7px;
                }
            }

            &.large {
                width: 16px;
                height: 16px;

                &:after {
                    width: 8px;
                    height: 8px;
                }
            }
        }

        &.disabled_radio_input {
            border-color: $primary-btn-disabled-color;
            cursor: not-allowed !important;
            color: $ghost-btn-active-color;

            &:after {
                background-color: $primary-btn-disabled-color;
                cursor: not-allowed !important;
            }
        }

        &.small {
            font-size: 12px;
            line-height: 15px;
        }

        &.medium {
            font-size: 14px;
            line-height: 17px;
        }

        &.large {
            font-size: 16px;
            line-height: 19px;
        }
    }
}