.carouselContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  margin: auto;
  border-radius: 10px;
  background-color: #00000080;
  position: relative;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1),
    /* Bottom shadow */ 0 -1px 2px 0 rgba(0, 0, 0, 0.1),
    /* Top shadow */ 1px 0 2px 0 rgba(0, 0, 0, 0.1),
    /* Right shadow */ -1px 0 2px 0 rgba(0, 0, 0, 0.1); /* Left shadow */
  .carouselButton {
    background-color: transparent;
    border: none;
    cursor: pointer;
    height: 25px;
    width: 25px;
    padding: 0;
    display: grid;
    place-items: center;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 1;
    background-color: #fff;
    border-radius: 50%;
    &:first-of-type {
      left: 5px;
    }
    &:last-of-type {
      right: 5px;
    }
  }
  .bottomNavigationContainer {
    position: absolute;
    width: 100%;
    bottom: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    z-index: 500;
    
  
    .dot {
      width: 7px;
      height: 7px;
      border-radius: 50%;
      background-color: #adadad;
      cursor: pointer;
      box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
      border: 1px solid rgba(255, 255, 255, 0.8);
    }
  
    .active {
      background-color: #fff;
    }}}

