.create_booking_page_main {
    background-color: white;
    padding-right: 10px;
  }
  
  .flex {
    display: flex;
  }
  
  .align_center {
    align-items: center;
  }
  
  .create_booking_page_top {
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #dddd;
    border-top: 1px solid #dddd;
  }
  
  .smsg_create_booking_page_top div:not(:last-of-type) {
    border-right: none !important;
    padding: 0px 0px !important;
   
    gap: 1px;
    /* height: 75px; */
  }
 
  .smsg_create_booking_page_top {
    border-bottom: none !important;
 
  }

  .smsg_room_plan {
      border-bottom: none !important;
      padding:  10px 10px 15px 10px !important;
  }
  
  .create_booking_page_top .create_booking_selectBox_container {
    padding: 10px 5px 10px 8px !important;
  }
  
  .create_booking_page_mid {
    padding: 15px;
    border-bottom: 1px solid #ddd;
  }
  
  .create_booking_page_bottom {
    background-color: white;
    /* padding: 20px; */
    border-radius: 4px;
    margin-bottom: 10px;
  }

  .create_booking_page_bottom_head {
    justify-content: space-between;
    padding: 10px 10px;
  }
  
  .create_booking_page_bottom_head h5 {
    font-size: 14px;
    font-weight: 600;
    color: #333333;
  }

 
  
  
  .create_booking_adults_box {
    width: 35px;
    height: 25px;
    padding: 0px 5px;
    outline: none;
    border: 1px solid #ddd;
    border-radius: 4px;
  }
  
  .create_booking_adult_deatils_box1 {
    padding-left: 20px;
  }
  
  .create_booking_adult_deatils_box2 {
    border-left: 1px solid #ddd;
    border-right: 1px solid #ddd;
    padding: 10px;
  }
  
  .create_booking_adult_deatils_box3 {
    border-right: 1px solid #ddd;
    padding: 10px;
  }
  
  .create_booking_adult_deatils_top {
    padding-bottom: 10px;
  }
  
  .adlut_label {
    font-size: 13px;
    font-weight: 400;
    color: #333333;
    cursor: pointer;
    color: #0056b3;
    text-decoration: underline;
  }
  
  .gap {
    gap: 20px;
  }
  
  .Create_booking_label_small {
    font-size: 12px;
    font-weight: 400;
    cursor: pointer;
    color: #333333;
  }
  
  .Create_booking_label {
    font-size: 13px;
    font-weight: 400;
    color: #333333;
    cursor: pointer;
  }
  
  .gap_7 {
    gap: 7px;
  }
  
  .adlut_label {
    color: #0056b3;
    text-decoration: underline;
  }
  
  .common_input {
    border: 1px solid #ddd;
    outline: none;
    cursor: pointer;
    border-radius: 4px;
    padding: 5px;
    justify-content: space-between;
    align-items: center;
  }
  
  .Create_booking_selectBox input {
    border: 0;
    outline: none;
    width: 90px;
  }
  
  .gap {
    gap: 20px;
  }
  
  body {
    background-color: #eff0f2;
  }
  
  
  
  .reservations_common {
    padding: 13px 5px !important;
  }
  
  .date_picker_input {
    padding: 3px 8px !important;
  }
  
  .avail_room {
    color: #15AA12;
    background-color: #DFF8DF;
    padding: 8px 10px;
    border-radius: 20px;
    margin-bottom: 5px;
  }
  
  .common_btn {
    background-color: #0335FC;
    color: white;
    padding: 10px 16px;
    border: 0;
    height: 36px;
    border-radius: 4px;
  }
  
  .reservation_page_col3 h5 {
    padding: 10px 20px;
  }
  
  .create_booking_page_room_plan {
    border-bottom: 1px solid #ddd;
    padding: 10px 20px;
  }
  
  .common_min_input,
  .rate_input_small {
    border: 1px solid #ddd;
    /* width: 80px; */
    border-radius: 4px;
    padding: 5px 2px;
    background-color: #EEEEEE;
    justify-content: space-between;
    align-items: center;
  }
  
  .common_min_input input,
  .rate_input_small input {
    border: 0;
    width: 40px;
    outline: none;
    background-color: transparent;
  }
  
  .rate_input_small input {
    height: 20px;
  }
  
  .create_booking_page_adult_details_box1 {
    padding-left: 20px;
  }
  
  .create_sendmessage_page_adult_details_box2 {
    margin-right: 14px;
 
  
    /* width: fit-content;
    padding: 10px;
    padding-left: 20px;
    border-left: 1px solid #ddd; */
  }

  .sent-message-wrap.border-right {
    border-right: 1px solid #ddd;
    padding-right: 12px;
  }

  
  .create_booking_page_adult_details_box3 {
    border-right: 1px solid #ddd;
    padding: 10px;
  }
  
  .adult_deatils_top {
    padding: 10px 0;
  }
  
  .create_booking_selectBox_container {
    display: flex;
    flex-direction: column;
  }
  
  .createBookingInputAndSelectTags {
    border: 1px solid #ddd;
    width: 135px;
    outline: none;
    cursor: pointer;
    border-radius: 4px;
    padding: 5px
  }
  
  .createBookingInputAndSelectTagsSmall {
    width: 45px;
    height: 20px;
    border: 1px solid #ddd;
    border-radius: 4px;
    outline: none;
    padding: 0px 5px;
    margin-bottom: 8px
  }
  
  .create_booking_page_left_booking_card_container {
    width: 79%;
    overflow-y: auto;
  
  }
  
  .create_booking_page_total_amount_card {
    width: 20%;
    background-color: white;
    border-radius: 4px;
    height: fit-content;
  
  
  }
  
  .crate_booking_page_total_amount_heading {
    display: flex;
    justify-content: space-between;
  }
  
  .crate_booking_page_total_amount_heading h4 {
    font-family: "Inter";
    font-weight: 500;
    font-size: 14px;
  
  
  }
  
  
  .crate_booking_page_total_amount_heading h3 {
    font-weight: 700;
    font-family: "Inter";
    font-size: 16px;
  }
  
  .create_booking_primary_guest_details_container {
    background-color: #fff;
    padding: 20px 10px;
    border-radius: 8px;
    width: 100%;
    margin-top: 10px;
  
  }
  
  .create_booking_primary_guest_details_container h4 {
    font-size: 14px;
    font-family: "Inter";
    font-weight: 500;
    margin-bottom: 10px;
    color: #000;
  }
  
  .create_booking_primary_guest_details {
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 50px;
    align-items: flex-end;
  
  }
  
  .create_booking_primary_guest_details_input_container {
    width: 31%;
  
  }
  
  .create_booking_primary_guest_details_input {
    margin-top: 5px;
    height: 25px;
    border: 1px solid #000;
    border-radius: 4px;
    outline: none;
    padding: 0 5px;
    width: 100%;
  }
  
  .create_booking_primary_guest_details_input_remarks {
    height: 100%;
    /* width: 500px; */
    margin-left: 5px;
    border: 1px solid #000;
    border-radius: 4px;
    outline: none;
    padding: 0 5px;
  }
  
  .crate_booking_page_total_amount_button_container {
    display: flex;
    gap: 10px;
    justify-content: space-between;
    padding: 10px;
    height: 60px;
    border-top: .5px solid #333;
    margin-top: 20px;
    flex-wrap: wrap;
    align-items: center;
    height: fit-content;
  
  
  }
  
  
  
  
  .create_booking_page_left_booking_card_container::-webkit-scrollbar {
    width: 4px;
    height: 2px;
  }
  
  .create_booking_page_left_booking_card_container::-webkit-scrollbar-track {
    background-color: #f1f1f1;
  }
  
  .create_booking_page_left_booking_card_container::-webkit-scrollbar-thumb {
    background-color: #ccc;
    /* color of the thumb */
    border-radius: 6px;
    /* roundness of the thumb */
  }
  
  .create_booking_page_left_booking_card_container::-webkit-scrollbar-thumb:hover {
    background-color: #ccc;
    /* color of the thumb on hover */
  }