.amenities_container {
    /* width: 300px; */
    border-radius: 8px;
    background: #fff;
    /* margin: auto; */
    /* padding: 20px; */
    position: relative;
}

.amenities_headings {
    display: flex;
    justify-content: space-between;
}

.amenities_input_field {
    width: 100%;
    height: 25px;
    padding: 5px;
    border-radius: 4px;
    border: 0.5px solid #333;
    margin: 10px 0;
}

.amenities_input_field:focus {
    outline: 1px solid #3968ed;
}

.amenities_headings>h2 {
    color: #333;
    font-family: "Inter";
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.amenities_headings>p {
    color: #3968ed;
    font-family: "Inter";
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.amenities_lists_container {
    display: flex;
    list-style: none;
    flex-wrap: wrap;
    gap: 10px;
    margin-top: 10px;
    padding: 0;
}

.amenities_lists {
    color: #888;
    font-family: "Inter";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    padding: 4px 7px;
    border-radius: 2px;
    background: #eee;
    list-style: none;
}

.amenities_suggestions {
    position: absolute;
    background-color: white;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    border-radius: 4px;
    overflow-y: auto;
    width: 85%;
    max-height: 150px;
}

.amenities_suggestions::-webkit-scrollbar {
    width: 7px;
}

.amenities_suggestions::-webkit-scrollbar-thumb {
    background-color: #d3d0d0;
}

.amenities_suggestions::-webkit-scrollbar-thumb:hover {
    background-color: #b9b7b7;
}

.amenities_suggestions::-webkit-scrollbar-track {
    background-color: #f2f2f2;
}

.amenities_single_suggestion,
.nodatafound {
    font-family: "Inter";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    padding: 5px;
    border: 4px;
}

.amenities_single_suggestion:hover {
    background-color: #f7f5f5;
    cursor: pointer;
}

.amenitiesError {
    color: red;
    font-family: "Inter";
    font-size: 11px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 5px;
}