@import "../settings/_settings";
@import "../settings/_globalMixins";

.label {
  @include label-styling;
}

.primary_input_container {
  width: 100%;
  .primary_input_div {
    position: relative;
    width: 100%;
    display: flex;
    align-content: center;

    .primary_input {
      border: 1px solid $ghost-btn-active-color;
      font-family: $Inter;
      font-weight: 400;
      border-radius: 4px;
      // color: $ghost-btn-active-color;
      color: $ghost-btn-color;
      cursor: text;
      width: 100%;

      &.small {
        font-size: 12px;
        padding: 3px 10px;
      }

      &.medium {
        font-size: 14px;

        padding: 5px 10px;
      }

      &.large {
        font-size: 16px;

        padding: 7px 10px;
      }

      &:focus-visible {
        outline: none;
      }

      &:not(:disabled):focus {
        border: 1px solid $primary-btn-color !important;
      }

      &.error {
        &:not(:disabled):focus {
          border: 1px solid red !important;
          

        }
        background-color: #FFEAEA;
        
      }

      &.disabled {
        background-color: $disabled-input-bg-color;
        border: 1px solid $primary-btn-disabled-color;
        color: #858585;
        cursor: not-allowed !important;
      }
    }

    .icon_left {
      position: absolute;
      left: 10px;
      top: 50%;
      transform: translateY(-50%);
      display: flex;
      align-items: center;
    }

    .icon_right {
      position: absolute;
      right: 10px;
      top: 50%;
      transform: translateY(-50%);
      display: flex;
      align-items: center;
    }
  }

  .error_text {
    font-family: "Inter";
    font-size: 10px;
    font-weight: 400;

    color: $error-color;
    display: block;
  }
}

.prefix_input_container {
  .prefix_input_div {
    position: relative;
    width: 100%;
    display: flex;
    align-content: center;

    .prefix_input {
      border: 1px solid $ghost-btn-active-color;
      font-family: $Inter;
      font-weight: 400;
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
      color: $ghost-btn-color;
      cursor: text;
      width: 100%;
      &.small {
        font-size: 12px;
        padding: 3px 10px;
      }

      &.medium {
        font-size: 14px;

        padding: 5px 10px;
      }

      &.large {
        font-size: 16px;

        padding: 7px 10px;
      }

      &:focus-visible {
        outline: none;
      }

      &:not(:disabled):focus {
        border: 1px solid $primary-btn-color;
      }

      &.error {
        border-color: red !important;

        &:not(:disabled):focus {
          border-color: red !important;
        }
      }

      &.disabled {
        background-color: $disabled-input-bg-color;
        border: 1px solid $primary-btn-disabled-color;
        color: #858585;
        cursor: not-allowed !important;
      }
    }

    &:focus-within .icon_left {
      border-color: $primary-btn-color;
    }

    .icon_left {
      border: 1px solid $ghost-btn-active-color;
      display: flex;
      align-items: center;
      padding: 8px;
      background-color: $ghost-btn-disabled-color;
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;

      &.small {
        font-size: 12px;

        padding: 3px 8px;
      }

      &.medium {
        font-size: 14px;

        padding: 5px 8px;
      }

      &.large {
        font-size: 16px;

        padding: 7px 8px;
      }
    }
  }


  .error_text {
    font-family: "Inter";
    font-size: 10px;
    font-weight: 400;

    color: $error-color;
    display: block;
    
  }
}

.suffix_input_container {
  margin-bottom: 10px;

  .suffix_input_div {
    position: relative;
    width: 100%;
    display: flex;
    align-content: center;

    .suffix_input {
      border: 1px solid $ghost-btn-active-color;
      font-family: $Inter;
      font-weight: 400;
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
      color: $ghost-btn-color;
      cursor: text;
      width: 100%;
      &.small {
        font-size: 12px;
        padding: 3px 10px;
      }

      &.medium {
        font-size: 14px;

        padding: 5px 10px;
      }

      &.large {
        font-size: 16px;

        padding: 7px 10px;
      }

      &:focus-visible {
        outline: none;
      }

      &:not(:disabled):focus {
        border: 1px solid $primary-btn-color;
      }

      &.error {
        border-color: red !important;

        &:not(:disabled):focus {
          border-color: red !important;
        }
      }

      &.disabled {
        background-color: $disabled-input-bg-color;
        border: 1px solid $primary-btn-disabled-color;
        color: #858585;
        cursor: not-allowed !important;
      }
    }

    &:focus-within .icon_left {
      border-color: $primary-btn-color;
    }

    .icon_left {
      border: 1px solid $ghost-btn-active-color;
      display: flex;
      align-items: center;
      padding: 8px;
      background-color: $ghost-btn-disabled-color;
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;

      &.small {
        font-size: 12px;

        padding: 3px 8px;
      }

      &.medium {
        font-size: 14px;

        padding: 5px 8px;
      }

      &.large {
        font-size: 16px;

        padding: 7px 8px;
      }
    }
  }

  .error_text {
    font-family: "Inter";
    font-size: 10px;
    font-weight: 400;

    color: $error-color;
    display: block;
   
  }
}

// &.error {
//     &:not(:disabled):focus {
//         border: 1px solid red !important;
//     }
// }

.char_count {
  position: absolute;
  display: flex;
  bottom: 3px;
  right: 5px;
  font-size: 12px;
  // padding-top: 5px;
  // background-color: #FFFFFF;
}