/* .DashboardCompetitorAnalysisTwoContainer {
  margin: 10px auto;
  background-color: #fff;
  border-radius: 10px;
  overflow: hidden;
}

.DashboardCompetitorAnalysisTwo-heading {
  padding: 15px 10px;
  color: #333;
  font-family: "Inter";
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.DashboardCompetitorAnalysisTwo-hr {
  height: 1px;
  background-color: #cad8ff;
}

.DashboardCompetitorAnalysisTwo-table {
  width: 100%;
  padding: 10px;
  border-collapse: collapse;
}

.DashboardCompetitorAnalysisTwo-table-header {
  color: #888;
  font-family: "Inter";
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-align: left;
  border-bottom: 0.5px solid #cad8ff;
}

.DashboardCompetitorAnalysisTwo-table-header>th,
.DashboardCompetitorAnalysisTwo-table-row>td {
  padding: 9px;
}

.DashboardCompetitorAnalysisTwo-table-row {
  border-bottom: 0.5px solid #cad8ff;
  color: #333;
  font-family: "Inter";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.DashboardCompetitorAnalysisTwo-AverageRates {
  font-size: 12px;
}

.dashboardCompetitorAnalysisTwo_backgroundColor {
  background-color: #ecf1fd;
} */




.DashboardCompetitorAnalysisTwoContainer {
  /* width: 80%; */
  margin: 10px auto;
  background-color: #fff;
  border-radius: 10px;
}
.DashboardCompetitorAnalysisTwo-heading {
  padding: 15px 10px;
  color: #333;
  font-family: "Inter";
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.DashboardCompetitorAnalysisTwo-hr {
  height: 1px;
  background-color: #cad8ff;
}
.DashboardCompetitorAnalysisTwo-table {
  width: 100%;
  padding: 10px;
  border-collapse: collapse;
}
.DashboardCompetitorAnalysisTwo-table-header {
  color: #888;
  font-family: "Inter";
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-align: left;
  border-bottom: 0.5px solid #cad8ff;
}
.DashboardCompetitorAnalysisTwo-table-header > th,
.DashboardCompetitorAnalysisTwo-table-row > td {
  padding: 10px;
  height: 12px;
}
.DashboardCompetitorAnalysisTwo-table-row {
  border-bottom: 0.5px solid #cad8ff;
  color: #333;
  font-family: "Inter";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.DashboardCompetitorAnalysisTwo-AverageRates {
  font-size: 12px;
}
.dashboardCompetitorAnalysisTwo_backgroundColor {
  background-color: #ecf1fd;
}

.DashboardCompetitorAnalysisTwo-table-data-upgradePlan {
  height: 15px;
  width: 30px;
  background-color: rgba(0, 0, 0, 0.3);
  border-radius: 10px;
}
.DashboardCompetitorAnalysisUpgradeHotelRowHotelName {
  height: 15px;
  width: 60px;
  background-color: rgba(0, 0, 0, 0.3);
  border-radius: 10px;
}
.DashboardCompetitorAnalysisTwo-upgradeContainer {
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(255, 255, 255, 0.3);
  backdrop-filter: blur(7.5px);
  min-height: 100%;
  max-height: fit-content;
  border-radius: 10px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
  overflow: hidden;
}
.DashboardCompetitorAnalysisTwo-upgradeBtn {
  display: flex;
  height: 35px;
  padding: 10px 15px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 4px;
  background: #3968ed;
  color: #fff;
  font-family: "Inter";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  border: #3968ed;
  cursor: pointer;
}
.DashboardCompetitorAnalysisTwo-upgradeText {
  width: 60%;
  text-align: center;
  margin: 0;
  color: #333;
  font-family: "Inter";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
@keyframes shimmer {
  0% {
    background-position: -1000px 0;
  }
  100% {
    background-position: 1000px 0;
  }
}

.shimmer {
  background: linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%);
  background-size: 200% 100%;
  animation: shimmer 1.5s infinite;
  color: transparent; /* Hide the text content during loading */
}