.ourStoreContainer {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  /* justify-content: space-between; */
  align-items: center;
  gap: 10px;
  padding: 20px;
}

.ourStoreItem {
  width: calc(33.3% - 10px);
  padding: 20px;
  border-radius: 6px;
}

.ourStoreItemHeadingItems {
  color: #333;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 130%;
  margin-bottom: 5px;
}