@import "../../Components/NewCustomComp/settings/globalMixins";

.targetsLayout {
    padding: 15px 0;
    margin-top: 60px;
}

.whiteWrapper {
    background-color: #FFFFFF;
    padding: 10px 12px;
    border-radius: 8px;
}


.targetsMain {
    padding: 15px;
    position: relative;



    .graphsWrapper {
        display: flex;
        justify-content: space-between;
        gap: 15px;

        .targetsGraphs {
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 25%;
            gap: 10px;
            // flex-direction: column-reverse;

            .graphHeading {
                width: 30%;

                h5 {
                    font-size: 12px;
                    color: #333333;
                    margin-bottom: 8px;
                    // font-weight: 600;

                }

                h2 {
                    color: #333333;
                    font-size: 18px;
                    font-weight: 600;
                }
            }

            .graphImg {
                width: 50%;
                overflow-y: scroll;
                height: 70px;
                display: flex;
                justify-content: center;
                align-items: center;
            }
        }
    }

    .tableWrapper {
        margin-top: 10px;
        display: flex;
        justify-content: center;
        gap: 10px;
        // background-color: #E03838;
        width: 100%;
        position: relative;

        .tableMain {
            width: 100%;
            border: 1px solid #CCCCCC;
            background-color: #FFFFFF;
            border-radius: 8px;
            overflow: hidden;
            table-layout: fixed;
            border-spacing: 0;

            .tableHeading {
                padding: 5px 10px;
                border-left: 1px solid #CCCCCC;
                text-align: left;
            

                &:first-child {
                    border: none;
                    width: 12%;
                }
            }


            .tableDataTd {
                border-top: 1px solid #CCCCCC !important;
                border-left: 1px solid #CCCCCC;
                padding: 5px 10px;
                vertical-align: middle;
                // &:first-child {
                //     width: 10%;

                // }
            }

            .tableDataMonth {
                
                &.active {
                    background-color: #ECF1FD;
                }
            }

            .innerData {
                display: flex;
                justify-content: space-between;
                align-items: center;


                .innerTable {
                    width: 70%;

                    .innerHeading {
                        color: #888888;
                        font-size: 12px;

                    }


                    .innerTd {
                        font-size: 14px;
                        width: 35%;
                        font-weight: 600;
                        color: #333333;
                    }
                }

                .overview {
                    width: max-content;
                    display: inline-block;
                    display: flex;
                    align-items: center;


                    .overviewTxt {
                        color: #15AA12;
                        white-space: nowrap;
                        font-size: clamp(12px, 3vw, 14px);
                    }

                    .overviewTxtRed {
                        color: #E03838;
                        white-space: nowrap;
                        font-size: clamp(12px, 3vw, 14px);

                    }
                }

            }


        }

        .curtainMain {
            height: 100%;
            overflow-x: scroll;
            position: absolute;
            right: 0;
            width: 0;
            // width: 100%;

            &.slideOn {
                animation: slideIn 1s ease-in-out forwards;
                width: 100%;
            }

            &.slideOff {
                // display: none;
                animation: slideOut 1s ease-in-out forwards;
                width: 0;
            }

            @keyframes slideIn {
                from {
                    width: 0;
                    opacity: 0;
                }

                to {
                    width: 89%;
                    opacity: 1;
                }
            }

            @keyframes slideOut {
                from {
                    width: 89%;
                    opacity: 1;
                }

                to {
                    width: 0%;
                    opacity: 0;
                }
            }

            .curtainTable_main {

                overflow-x: scroll;
                border-top-left-radius: 0;
                border-bottom-left-radius: 0;

                .curtainTable_heading {
                    width: 100%;
                }
            }

        }


        .curtainTableWrapper {
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;


            .tableDataTd {


                &.openIcon {
                    svg {
                        circle {
                            fill: #3968ED;
                            stroke: #3968ED;
                        }
                    }
                }

                &.CloseIcon {
                    svg {
                        fill: #3968ED;
                        stroke: #3968ED;
                    }
                }
            }
        }
    }


    .settingsIcon {
        position: absolute;
        right: 0;
        margin-right: 15px;
        height: fit-content;
        top: 15px;
        width: fit-content;
        //    background-color: #E03838;
    }

    .settingsMain {
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        width: 400px;
        height: 100%;
        overflow: hidden;

        .settingList {
            position: absolute;
            width: 100%;
            border-radius: 5px;
            padding: 10px 20px 20px;
            background-color: #FFFFFF;
            transition: transform 0.5s ease-in-out;

            .settingsWrapper {
                border: 1px solid #CCCCCC;
                border-radius: 8px;
                margin-top: 15px;
                width: 100%;


                tr {
                    th {
                        text-align: left;
                        padding: 5px;
                    }

                    td {
                        padding: 5px;
                        border-top: 1px solid #CCCCCC;
                        border-spacing: 0;
                    }
                }

                // .settingTableTd {
                //     // width: 25%;

                // }
            }

            &.listHide {
                transform: translateX(-100%);
                left: 0;
            }

            &.listShow {
                transform: translateX(0);
            }

        }

        .settingsAdd {
            position: absolute;
            width: 100%;
            border-radius: 5px;
            padding: 10px 20px 20px;
            background-color: #FFFFFF;
            transition: transform 0.5s ease-in-out;

            &.addShow {
                transform: translateX(0);
            }

            &.addHide {
                transform: translateX(100%);
                right: 0;
            }
        }


    }
}


.analysisWrapper {

    overflow: auto;
    width: 100%;
    position: relative;
    border: 1px solid #CCCCCC;
    border-radius: 8px;
    @include scrollbar-styling;

    .analysisTable {
       
        width: 100%;
        
        // border-spacing: 0;
        table-layout: fixed; /* Ensures equal-width columns */
        border-collapse: collapse;

        //    overflow: hidden;

        tr {
            th {
                text-align: left;
                padding: 10px 15px;
                border-left: 1px solid #CCCCCC;
                // width: 120px;

                &:first-child {
                    border-left: none;
                    

                }
            }

            td {
                padding: 5px 10px;
                padding: 10px 15px;
                border-left: 1px solid #CCCCCC;
                border-top: 1px solid #CCCCCC;
                // width: 12.5%;


                &:first-child {
                    border-left: none;
                }

                // &.emptyRow {
                //     border-bottom:1px solid  #CCCCCC;
                // }
            }

            .analysisFixed {

                box-shadow: 4px 0px 10px 0px #00000014;
                position: sticky;
                width: 170px;
                z-index: 10;
                left: 0;
                background-color: #FFFFFF;

                &.highlight {
                    background-color: #ECF1FD;
                }
            }
            .highlight{
                background-color: #ECF1FD;
            }

            .analysisRatesTd {
                display: flex;
                justify-content: space-between;
                align-items: center;
                width: 100%;
                height: 100%;

                .analysisAmount {
                    width: 80px;

                    &.bold {
                        font-size: 16px;
                        font-weight: 600;
                    }
                }

                .analysisLow {
                    color: #E03838;
                    white-space: nowrap;
                        font-size: clamp(12px, 3vw, 14px);

                }
                .analysisHigh {
                    color: #15AA12;
                    white-space: nowrap;
                        font-size: clamp(12px, 3vw, 14px);
                }
            }


        }
    }
}



.popAddTargets {
    background: #f1f2f4;
    border-radius: 8px;
    padding: 10px 15px;
    transform: scale(0);
    transition: all 0.3s ease-in-out;

    .crossBtn {
        cursor: pointer;
        background-color: #FFF;
        width: fit-content;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 3px;
        border-radius: 5px;
    }

    &.show {
        animation: zoomIn 1s cubic-bezier(0, -0.01, 0.12, 1.33) forwards;
    }

    &.hide {
        animation: zoomOut 1s cubic-bezier(0, -0.01, 0.12, 1.33);
    }

    @keyframes zoomIn {
        from {
            transform: scale(0);
        }

        to {
            transform: scale(1);
        }
    }

    @keyframes zoomOut {
        from {
            transform: scale(1);
        }

        to {
            transform: scale(0);
        }
    }
}

