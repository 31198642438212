.waiterDashBoardContainer {
  background: #eff0f2;
  padding: 10px;
}

.waiterDashBoardHeading {
  color: #333;
  font-family: "Inter";
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin: 0;
  padding: 0;
}
.waiterDashBoardDate {
  color: #3968ed;
  font-family: "Inter";
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 10px;
}

.waiterDashBoardDatesContainer{
  margin-top: 15px;
  margin-bottom: 20px;
}

.waiterDashBoardOrdersContainer {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
}
.waiterDashBoardOrderCard {
  width: 266px;
  /* height: 330px; */
  padding: 20px;
  border-radius: 10px;
  background: #fff;
}
.waiterDashBoardOrderCardTop {
  display: flex;
  justify-content: space-between;
}
.waiterDashBoardOrderCardHeading {
  color: #333;
  font-family: "Inter";
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin: 0;
  padding: 0;
}
.waiterDashBoardOrderCardTime {
  color: #333;
  text-align: right;
  font-family: "Inter";
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  margin: 0;
  padding: 0;
}

.waiterDashBoardOrderCardHr {
  height: 0.5px;
  background: #ccc;
  margin: 10px 0;
}

.waiterDashBoardOrderCardItem {
  color: #333;
  font-family: "Inter";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
