.waiterDashboardCreateKotContainer {
  width: 565px;
  margin: 0 auto;
}
.waiterDashboardCreateKotHeading {
  color: #333;
  font-family: "Inter";
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin: 0 0 20px 0;
  padding: 0;
}
.waiterDashboardCreateKotTable {
  width: 100%;
  padding: 20px 10px;
  flex-shrink: 0;
  border-radius: 8px;
  background: #fff;
}
.waiterDashboardCreateKotItemsContainer {
  margin-top: 20px;
}
.waiterDashboardCreateKotTable > select {
  width: 150px;
  height: 30px;
  flex-shrink: 0;
  border-radius: 4px;
  border: 0.5px solid #333;
}
.waiterDashboardCreateKotTable > select:focus {
  outline: none;
}
.waiterDashboardCreateKotItemsHeading {
  color: #333;
  font-family: "Inter";
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin: 0 0 10px 0;
  padding: 0;
}
.waiterDashboardCreateKotItemsSelectContainer {
  display: flex;
  justify-content: space-between;
}
.waiterDashboardCreateKotItemsLabel {
  color: #333;
  font-family: "Inter";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.waiterDashboardCreateKotItemsSelect {
  width: 180px;
  height: 30px;
  flex-shrink: 0;
  border-radius: 4px;
  border: 0.5px solid #333;
  margin-top: 5px;
}
.waiterDashboardCreateKotItemsSelect:focus {
  outline: none;
}
.waiterDashboardCreateKotItemsSelectQuantity {
  width: 60px;
  height: 30px;
}
.waiterDashboardCreateKotItemsButtonContainer {
  align-self: end;
}
.waiterDashboardCreateKotItemsButton {
  border: none;
  background-color: transparent;
  color: #3968ed;
  font-family: "Inter";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  cursor: pointer;
}
.waiterDashboardCreateKotButton {
  color: #fff;
  font-family: "Inter";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  display: inline-flex;
  height: 35px;
  padding: 10px 15px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
  border-radius: 4px;
  background: #3968ed;
  border: none;
  margin-top: 20px;
}
.waiterDashboardCreateKotItemsHr {
  width: 100%;
  height: 0.5px;
  background: #ccc;
  margin-top: 15px;
  margin-bottom: 15px;
}
.waiterDashboardCreateKotDisplayItems {
  color: #333;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin: 0;
  padding: 0;
}
.menuItemsToDisplayContainer {
  display: flex;
  justify-content: space-between;
}
.waiterDashboardCreateKotItemsDeleteButton {
  border: none;
  background: transparent;
  cursor: pointer;
  color: #e03838;
  font-family: "Inter";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.waiterDashboardCreateKotTableButtonContainer {
  padding: 0;
}
@media screen and (max-width: 768px) {
  .waiterDashboardCreateKotContainer {
    width: 100%;
    margin: 0 auto;
  }

  .waiterDashboardCreateKotTable {
    width: 100%;
  }

  .waiterDashboardCreateKotTable > select {
    width: 100%;
  }

  .waiterDashboardCreateKotItemsSelectContainer {
    flex-direction: column;
    gap: 10px;
  }

  .waiterDashboardCreateKotItemsSelect {
    width: 100%;
  }

  .waiterDashboardCreateKotItemsSelectQuantity {
    width: 100%;
  }
  .waiterDashboardCreateKotItemsButtonContainer {
    align-self: flex-start;
  }

  .waiterDashboardCreateKotButton {
    width: 100%;
  }
}
