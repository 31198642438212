//  ==== top tooltip ==========

.tooltip-container-top {
  position: relative;
  display: inline-block;
  cursor: pointer;
  .tooltip-text {
    visibility: hidden;
    opacity: 0;
    text-align: center;
    position: absolute;
    z-index: 1;
    bottom: 130%;
    left: 50%;
    transform: translateX(-50%);
    transition: opacity 0.3s;
    min-width: 100px;
    max-width: 400px;
  }
  & ::after {
    content: "";
    position: absolute;
    top: 100%; /* Below the tooltip */
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: #3968ed transparent transparent transparent;
  }
}

//  ==== bottom tooltip ==========

.tooltip-container-bottom {
  position: relative;
  display: inline-block;
  cursor: pointer;
  padding: 5px;
  .tooltip-text {
    visibility: hidden;
    opacity: 0;
    text-align: center;
    position: absolute;
    z-index: 1;
    top: 130%;
    left: 50%;
    transform: translateX(-50%);
    transition: opacity 0.3s;
    min-width: 100px;
    max-width: 400px;
  }
  & ::after {
    content: "";
    position: absolute;
    bottom: 100%; /* Below the tooltip */
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent transparent #3968ed transparent;
  }
}

// ===== right tooltip ========
.tooltip-container-right {
  position: relative;
  display: inline-block;
  cursor: pointer;
  .tooltip-text {
    visibility: hidden;
    opacity: 0;
    text-align: center;
    position: absolute;
    z-index: 1;
    top: 50%;
    transform: translateY(-50%);
    left: 110%;

    transition: opacity 0.3s;
    min-width: 100px;
    max-width: 400px;
  }
  & ::after {
    content: "";
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 100%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent #3968ed transparent transparent;
  }
}

// ===== left tooltip ========
.tooltip-container-left {
  position: relative;
  display: inline-block;
  cursor: pointer;
  .tooltip-text {
    // visibility: hidden;
    // opacity: 0;
    text-align: center;
    position: absolute;
    z-index: 1;
    top: 50%;
    transform: translateY(-50%);
    right: 110%;
    transition: opacity 0.3s;
    max-width: 400px;
    min-width: 100px;
  }
  & ::after {
    content: "";
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 105%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent transparent transparent #3968ed;
  }
}

// ====styling for the visibility of tooltip  ========

.tooltip-container-left:hover .tooltip-text {
  visibility: visible;
  opacity: 1;
}
.tooltip-container-right:hover .tooltip-text {
  visibility: visible;
  opacity: 1;
}
.tooltip-container-top:hover .tooltip-text {
  visibility: visible;
  opacity: 1;
}
.tooltip-container-bottom:hover .tooltip-text {
  visibility: visible;
  opacity: 1;
}

// ==== end styling for the visibility of tooltip  ========

// tool tip text
.tooltipFontStyle {
  font-family: "Inter";
  font-weight: 400;
  font-size: 10px;
  color: #fff;
  background-color: #3968ed;
  border-radius: 4px;
  padding: 5px;
  line-height: 12px;
}
