 @import '../settings/_globalMixins';
.time-picker-input::-webkit-calendar-picker-indicator {
    opacity: 0;
    cursor: pointer; 
    position: absolute;
    width: 100%;
    height: 100%;
  }
  .label{
    @include label-styling;
  }
  
  /* Custom styles for the input */
  .time-picker-input {
    position: relative;
    width: 100%;
    height: 100%;
    // color: #ADADAD;
    border: none;
    &:focus {
      outline: none;
    }

  }
  
  .time-picker-container {
    display: flex;
    align-items: center;
    border: 1px solid #ADADAD;
    background-color: #fff;
    padding: 5px;
    gap: 5px;
    border-radius: 4px;
    
    &.small {
      height: 23px;
      padding: 3px 5px;
      font-size: 12px;
    }
    &.medium {
      height: 29px;
      font-size: 12px;
       padding: 4px 5px;
    }
   &.large {
    height: 35px;
    font-size: 14px;
     padding: 4px 5px;
   
    }

  }
  
  .time-picker-container:focus-within{
    border: 1px solid #3968ed;
  }