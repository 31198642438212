
.lms_create_reservation_vertical_inputs_container input {
    width: 92%;
    /* width: 45px; */
    height: 28px;
    border: 1px solid #ddd;
    border-radius: 4px;
    outline: none;
    padding: 6px 5px;
    margin-top: 1px;
    margin-bottom: 0px;
  }
  .flex{
    display: flex;
  }
  .gap_7{
    gap: 7px;
  }
  
  .lms_create_reservation_vertical_inputs_container {
    width: 100px;
  }
  .Create_booking_label{
    font-size: 14px;
    font-weight: 400;
    color: #333333;
    cursor: pointer;
}
.Create_booking_label_small{
  font-size: 12px;
  font-weight: 400;
  cursor: pointer;
  color: #333333;
}
.adlut_box_input{
  width: 45px;
  border-radius: 4px;
  border: 1px solid #ddd;
}