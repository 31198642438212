.user_data_parent {
  position: relative;
}

.user_data_parent ul {
  border-radius: 10px;
  max-height: 200px;
  box-shadow: 0 4px 4px 0px rgba(127, 155, 179, 0.1),
    0 6px 20px 0 rgba(0, 0, 0, 0.1);
  overflow-x: hidden;
  overflow-y: auto;
  position: absolute;
  top: 60px;
  width: 100%;
  background: #fff;
  z-index: 99;
}

.user_data_parent ul li {
  padding: 5px 10px;
  text-align: left;
  cursor: pointer;
  list-style: none;
}
/* .user_data_parent input {
    padding: 5px 12px;
    width: 350px;
    min-height: 2.25rem;
    outline: none;
    border: 1px solid #abb1ba;
    border-radius: 5px;
  }
  .user_data_parent input:focus {
    border: 1px solid blue;
  }

  .user_list_parent {
    padding: 5px 10px;
  }

   */
