.createContractContainer {
  background-color: #fff;
  margin: 20px 0;
  padding: 10px;
  border-radius: 8px;
  font-family: "Inter";

  .hr {
    margin: 10px 0;
  }
  .roomTypeContainer {
    // border-top: 1px solid #888;

    .checkboxLabelContainer {
      font-weight: 600;
      font-size: 16px;

      label {
        cursor: pointer;
      }
    }
    .availableRoomsContainer {
      .availableRoomsChild {
        label {
          font-weight: 600;
        }
        input {
          margin-left: 10px;
          width: 50px;
          padding: 5px;
          border-radius: 4px;
          border: 1px solid #888;
          &:focus {
            outline: none;
          }
        }
      }
    }
    .mealPlanContainer {
      table {
        width: 100%;
        border-spacing: 0;

        &:last-of-type {
          border-bottom: 0px;
        }
        .tableMealPlanName {
          width: 20%;
          font-weight: 600;
        }

        td {
          text-align: left;
          padding: 5px;

          input {
            width: 100%;
            border: 1px solid #888;
            padding: 5px;
            border-radius: 4px;
            margin-top: 3px;
            &:focus {
              outline: none;
            }
          }
        }
      }
    }
  }
  .releaseRestrictionContainer {
    h4 {
      font-weight: 600;
    }
    div {
      select {
        border: 1px solid #888;
        padding: 5px;
        border-radius: 4px;
        cursor: pointer;
        &:focus {
          outline: none;
        }
      }
    }
  }
}
