.waiterDashboardEditModifyKotContainer {
  width: 400px;
  border-radius: 10px;
  background: #fff;
  padding: 20px 0 0 0;
  margin: auto;
  max-height: 700px;
  overflow: auto;
}
.waiterDashboardEditModifyKotContainerHeader {
  display: flex;
  justify-content: space-between;
  padding: 0 20px;
}
.waiterDashboardEditModifyKotContainerHr {
  margin: 10px 0;
  height: 0.5px;
  background: #ccc;
  border: none;
}
.waiterDashboardEditModifyKotContainerBody {
  padding: 0 20px;
}

.waiterDashboardEditModifyKotContainerBodyHr {
  margin: 15px 0;
  height: 0;
  border: 0.3px dashed #ccc;
}
.waiterDashboardEditModifyKotContainerBodyItem {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}
.waiterDashboardEditModifyKotContainerBodyItemNames {
  color: #333;
  font-family: "Inter";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.waiterDashboardEditModifyKotContainerFooter {
  padding: 0 20px;
}
.waiterDashboardEditModifyKotContainerHeaderCloseButton {
  background-color: transparent;
  border: none;
  cursor: pointer;
}
.waiterDashboardEditModifyKotBalanceAmount {
  color: #e8b41a;
  font-family: "Inter";
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.waiterDashboardEditModifyKotFooter {
  padding: 10px;
  border-radius: 0px 0px 10px 10px;
  margin-top: 10px;
}

.waiterDashboardEditModifyKotFooterChild {
  display: flex;
  justify-content: center;
  gap: 5px;
  align-items: end;
}
.waiterDashboardEditModifyKotFooterText {
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: capitalize;
}
.waiterDashboardEditModifyKotFooterTextSmall {
  color: #888;
  font-family: "Inter";
  font-size: 8px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.waiterDashboardEditModifyKotFooterReasonText {
  color: #333;
  margin-top: 5px;
  text-align: center;
  font-family: "Inter";
  font-size: 10px;
  font-style: italic;
  font-weight: 400;
  line-height: normal;
}
