.custom_index_table{
    font-family: "Inter";
    border-collapse: collapse;
    text-align: left;
    width: 100%;
    background-color: #fff;
    border-radius: 8px;
    
 
 }
 .custom_index_table_head_rows {
  border-bottom: 1px solid #ccc;
 }
 .custom_index_table_head_row{
     background-color: #f1f2f4;
     color: #616a75;
     font-size: 12px;
    
 }
 .custom_index_table_head_row th{
 
    font-weight: 600;
    padding: 10px;
    border-radius: 8px;
    background-color: #f1f2f4;
 }
 .custom_index_table tr td{
     font-weight: 400;
     padding: 4px 10px;
     line-height: 17px;
     font-weight: 12px;
 }
 
 .custom_index_table_body_row{
     border-top:1px solid #ddd;
     cursor: pointer;
 }
 .custom_index_table_body_row:hover{
     background-color: #f5f7fa;
 }
 .custom_index_table_body_row_selected{
     border-top:1px solid #ddd;
     background-color: #eaf1fffb;
 }
 .custom_index_table_head{
     position: sticky;
     top: 0;
 }
 
 
 
 
 
 
 
 
 /* checkbox styling */
 .custom-checkbox {
     position: relative;
     border: 2px solid #000;
     border-radius: 2px;
     background: none;
     cursor: pointer;
     line-height: 0;
     margin: 0 0.6em 0 0;
     outline: 0;
     padding: 0 !important;
     vertical-align: text-top;
     height: 16px;
     width: 16px;
     /* -webkit-appearance: none; */
     appearance: none;
     opacity: 0.5;
     border-radius: 2px;
   }
   .custom-checkbox:hover {
     opacity: 1;
   }
   .custom-checkbox:checked {
     background-color: #3968ed;
     border: 2px solid #3968ed;
     opacity: 1;
   }
   .custom-checkbox:before {
     content: "";
     position: absolute;
     right: 50%;
     top: 50%;
     width: 40%;
     height: 80%;
     border: solid #fff;
     border-width: 0 3px 3px 0;
     margin: -1px -1px -1px -1px;
     transform: rotate(45deg) translate(-50%, -50%);
     
   }
   .custom-checkbox-two {
     position: relative;
     border: 2px solid #3968ed;
     border-radius: 2px;
     background: none;
     cursor: pointer;
     line-height: 0;
     margin: 0 0.6em 0 0;
     outline: 0;
     padding: 0px !important;
     vertical-align: text-top;
     height: 16px;
     width: 16px;
     /* -webkit-appearance: none; */
     appearance: none;
     border-radius: 2px;
     background-color: #3968ed;
     border-radius: 2px;
   }
   .custom-checkbox-two:before {
     content: "";
     position: absolute;
     width: 100%;
     height: 2px;
     background-color: #fff;
     top: 40%;
     
   }

  