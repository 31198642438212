.void_bookings_table {
  width: 100%;
  border: 1px solid #ddd;
  border-radius: 8px;
  border-collapse: collapse;
  th {
    text-align: left;
    padding: 5px;
    // border-bottom: 1px solid #ddd;
  }
  td {
    padding: 5px;
    // border-bottom: 1px solid #ddd;
  }

  tr {
    border-bottom: 1px solid #ddd;
  }
}
