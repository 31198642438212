
.settlementPopUpWrapper{
  -webkit-backdrop-filter: blur(5px);

  backdrop-filter: blur(5px);
  transition: all 1s;
  position: absolute;
  /* top: 40%; */
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  border-radius: 8px;
  flex-direction: column;
  z-index: 10000000000000;
  width: 600px;
/* padding: 25px; */
transition: all 5s ease-in;
background-color: white;
box-shadow: 0px 0px 11px -6px rgba(0, 0, 0, 0.75);
}

.settlementValuesHeading{
  color: rgba(57, 104, 237, 1);
  font-family: Inter;
  font-size: 12PX;
}

.settlementHeading {
  color: var(--333333, #333);
  font-family: "Inter";
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-align: left;
}
.settlementContainer {
  background-color: #fff;
  padding: 10px;
  width: fit-content;
}
.settlementTable {
  width: 100%;
  /* border-collapse: collapse; */
}
.settlementHeadingContainer {
  padding: 0 0 10px 0;
  border-bottom: 0.5px solid #ccc;
}

.settlementBodyLabel {
  color: #000;
  font-family: "Inter";
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-align: left;
}
.settlementBodyInput {
  height: 26px;
  width: 90%;
  flex-shrink: 0;
  border-radius: 4px;
  border: 0.5px solid var(--333333, #333);
  padding: 2px 5px;
  margin-top: 5px;
  font-family: "Inter";
  margin-right: 5px;
}
.settlementBodyInput:focus {
  outline: none;
}
.settlementBodyButtonContainer {
  display: flex;
  gap: 5px;
  padding-top: 10px;
}
.settlementBodyButton {
  background-color: transparent;
  border: none;
  cursor: pointer;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

.settlementBodyInputNotes {
  width: 300px;
}
