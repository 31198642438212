.insights {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 8px;
}
.inventory-container {
  padding: 20px;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  background-color: #fff;
  width: 100%;
  margin: 0 auto;
  h2{
    font-size: 18px;
    font-weight: bold;
   }
}

.insight-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 1px solid #ddd;
  border-radius: 8px;
  height: 56px;
  h3 {
    margin: 0;
    font-size: 16px;
    color: #333;
  }
}



.insight-box {
  p {
    
    color: #666;
  }
}
.filter {
  display: flex;
  justify-content: flex-end;
  
  select {
    padding: 5px 10px;
    border: 1px solid #007bff;
    border-radius: 4px;
    background-color: #fff;
    color: #007bff;
    font-size: 14px;
    width: 150px;
    cursor: pointer;
    &:focus{
      outline: none;
    }
  }
}
