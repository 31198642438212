.carousel {
  position: relative;
  width: 100%;
  overflow: hidden;
}

.carousel__track-container {
  width: 100%;
  display: flex;
  overflow: hidden;
}

.carousel__track {
  display: flex;
  transition: transform 0.4s ease-in-out;
  width: 100%;
}

.carousel__slide {
  min-width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.carousel__slide img {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}

.carousel__button {
  position: absolute;
  top: 40%;
  transform: translateY(-50%);
  background: none;
  border: none;
  cursor: pointer;
  color: white; /* Adjust button color as needed */
  z-index: 20;
  cursor: pointer;
  border: 2px solid #ddd;
  border-radius: 50%;
  align-items: center;
  height: 30px;
  width: 30px;
}
/* .carousel__button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  border: 0;
  background-color: transparent;
  cursor: pointer;
  font-size: 2rem; 
  color: white; 
  z-index: 20;
  cursor: pointer;
  border: 2px solid #ddd;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30px;
  width: 30px;
} */

.carousel__button--left {
  left: 30px;
}

.carousel__button--right {
  right: 30px;
}

.carousel__nav {
  display: flex;
  justify-content: center;
  padding: 10px 0;
}

.carousel__indicator {
  border: none;
  background-color: rgba(0, 0, 0, 0.5);
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin: 0 5px;
  cursor: pointer;
}

.carousel__indicator.current-slide {
  background-color: rgba(0, 0, 0, 0.9);
}
