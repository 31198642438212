@import '../settings/_globalMixins';
.date_picker_input::-webkit-calendar-picker-indicator {
  opacity: 0;
  cursor: pointer;
  position: absolute;
  width: 100%;
  height: 100%;
}

.date_picker_input {
  opacity: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  // color: #ADADAD;
  border: none;
  &:focus {
    outline: none;
  }
}
.date_picker_input_text{
 
  width: 100%;
  height: 100%;
  color: #333;
  border: none;
  &:focus {
    outline: none;
  }
}
.date_picker_container {
    display: flex;
    align-items: center;
    border: 1px solid #ADADAD;
    background-color: #fff;
    padding: 5px;
    gap: 5px;
    border-radius: 4px;
    position : relative;


  }
  
  .date_picker_container:focus-within{
    border: 1px solid #3968ed;
  }
  .label{
    @include label-styling;
  }