@media print {
  .foodBillContent {
    print-color-adjust: exact;
  }
  html {
    overflow: hidden !important;
    margin: 0px;
    padding: 0px;
  }

  .hideOnPrint {
    display: none;
  }

  .foodBillContent {
    background-color: #fff;
    /* width: 1200px !important; */
    /* margin: 110px auto; */
    /* padding: 20px; */
    /* max-width: 1024px; */
    /* height: 1011px; */
    padding: 0px !important;
    margin: 0px auto !important;

    overflow: hidden !important;
  }
  .foodBillContentNew {
    background-color: #fff;
    /* width: 1200px !important; */
    /* margin: 110px auto; */
    /* padding: 20px; */
    /* max-width: 1024px; */
    /* height: 1011px; */
    padding: 0px !important;
    height: 100px;
    margin: 0px auto !important;

    overflow: hidden !important;
  }

  body {
    overflow: hidden;
    margin: 0px;
    padding: 0px;
  }

  .editFolioSummeryBillContainer {
    overflow-y: hidden !important;
    overflow-x: hidden !important;
  }
}

.foodBillContent {
  /* background-color: #fff;
  width: 80%;
  margin: 110px auto;
  padding: 20px;
  height: inherit; */
  background-color: #fff;
  width: 45%;
  margin: 110px auto;
  padding: 20px;
  /* max-width: 1024px; */
  height: 1030px;
}

.editFolioSummeryBillContainer {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  overflow-y: auto;
  z-index: 1;
}

.editFolioSummeryBillContainerChild {
  width: 700px;
  border-radius: 8px;
  background-color: #fff;
  padding: 20px;
  margin: 10% auto;
}

.editFolioSummeryBillContainerChildRadioContainer {
  display: flex;
  gap: 15px;
  align-items: center;
  margin: 10px 0;
}

.editFolioSummeryBillContainerChildRadioLabel {
  font-family: "Inter";
  font-size: 12px;
}

.editFolioSummeryBillContainerChildRadioBtn {
  margin-right: 5px;
}

.editFolioSummeryBillContainerChildBtn {
  background-color: transparent;
  border: none;
  float: right;
  cursor: pointer;
}

/*  */
.popup-preview {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
}

.popup-content {
  background-color: #fff;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  width: 90%;
  height: 90%;
  overflow: auto;
}

.close-btn {
  background-color: transparent;
  border: none;
  color: #333;
  font-size: 16px;
  cursor: pointer;
  margin-bottom: 10px;
}

.close-btn:hover {
  color: #000;
}

iframe {
  width: 100%;
  height: 100%;
  border: none;
}

/* foodBill */
.foodBillContentNew {
  background-color: #fff;
  width: 30%;
  margin: 110px auto;
  padding: 20px;
  /* max-width: 1024px; */
  height: 500px;
  overflow: hidden;
  display: table;
}
@media screen and (max-width: 1400px) {
  .foodBillContent{
    width: 64%;
  }
}