.kot_page_container {
  width: 100%;
}

.kot_page_left,
.kot_page_right,
.kot_page_mid {
  background-color: #ffff;
  border-radius: 6px;
}

.kot_page_left {
  width: 15%;
}

.kot_page_right {
  width: 35%;
  overflow: auto;
  display: flex;
  flex-direction: column;
}

.kot_page_mid {
  width: 50%;
}

.kot_page_left h4 {
  padding: 10px;
  background-color: #ecf1ff;
}

.fav_items_list{
  border: 1px solid #CCC;
  border-radius: 7px;
  overflow: hidden;
}

.fav_items_list li {
  list-style-type: none;
  padding: 10px;
}

.fav_items_list li:not(:last-of-type) {
  border-bottom: 1px solid #333333;
}

.fav_items_list li button {
  border: 0;
  background-color: transparent;
  font-size: 14px;
  font-weight: 400;
  cursor: pointer;
}

.table_order {
  background-color: #3968ed;
  text-align: center;
  padding: 5px 10px;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  color: white;
}

.select_kot_item {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 391px;
  width: 155px;
  margin: 0 auto;
}

.select_kot_item p {
  font-size: 28px;
  color: #aaaaaa;
  font-weight: 400;
}

.select_kot_item span {
  font-size: 14px;
  color: #aaaaaa;
  font-weight: 400;
  padding: 0px 15px;
}

.kot_page_searchbox_container {
  padding: 10px;
  border-bottom: 1px solid #dddd;
}

/* .kot_item_container {
  flex-wrap: wrap;
  width: 100%;
  gap: 15px;
  padding: 10px;
} */
.kot_item_container {
  padding: 5px;
  width: 100%;
  display: flex;
  flex: 0 0 100%;
  max-width: 100%;
  flex-wrap: wrap;
  justify-items: center;
  align-items: center;
  align-content: center;
}

/* .kot_item {
  padding: 10px;
  height: 37px;
  text-align: center;
  display: flex;
  justify-content: center;
  border-radius: 4px;
  color: #ffff;
  align-items: center;
} */
.kot_item {
  align-items: center;
  border-radius: 4px;
  color: #fff;
  display: flex;
  height: 50px;
  justify-content: center;
  text-align: center;
  flex: 0 0 32.3333333333%;
  max-width: 33%;
  padding: 2px;
  margin-left: 2px !important;
  margin-right: 2px !important;
  margin-bottom: 2px !important;
  margin-top: 2px;
  cursor: pointer;
  font-size: 12px;
  font-weight: 600;
}

.kot_item_veg {
  background-color: #15aa12;
}

.kot_item_non_veg {
  background-color: #e03838;
}

.kot_item_eggtarian {
  background-color: #bd941b;
}

.kot_page_room_order_table {
  width: 100%;
  text-align: left;
  overflow: scroll;
  border-spacing: 0px;
  /* min-height: 500px; */
}

/* .kot_page_room_order_table tr{
    padding: 10px 20px;
} */
.kot_page_room_order_table td{
  font-size: 12px;
  
}
.kot_page_room_order_table th {
  background-color: #EEEEEE;
  font-size: 12px;
  font-weight: 600;
  padding-top: 5px;
  padding-bottom: 5px;
}

/* .kot_page_room_order_table th:first-child,
.kot_page_room_order_table td:first-child {
  padding-left: 10px;
}

.kot_page_room_order_table th:last-child,
.kot_page_room_order_table td:last-child {
  padding-right: 10px; 
} */

.kot_page_room_order_qty_btn {
  border: 0;
  background-color: transparent;
  font-size: 18px;
  font-weight: 500;
  cursor: pointer;
}

.kot_page_room_order_qty_value {
  border: 1px solid #ddd;
  padding: 0px 10px;
  border-radius: 4px;
}

.text_align_end {
  text-align: end;
}

.kot_page_room_order_table_footer {
  position: fixed;
  bottom: 0;
  z-index: 999;
}

.comment-popup-content {
  background-color: white;
  border-radius: 6px;
  padding: 10px 20px;
  /* height: 100px; */
}

.fav_items_inner_list:hover {
  background-color: #ecf1ff;
}

/* table,
tr td {
  border: 1px solid red;
} */
.tBodyCreateKot {
  display: block;
  /* height: 50%; */
  overflow: auto;
}

.tHeadCreateKot,
.tBodyCreateKot tr {
  display: table;
  width: 100%;
  table-layout: fixed;
  /* even columns width , fix width of table too*/
}

.tHeadCreateKot{
  overflow: hidden;
}

