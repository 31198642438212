.skeleton-table{
    width: 100%;
    border-spacing: 20px;
    background-color: #fff;
    border-radius: 8px;
    margin:0 0 20px 0 ;
}
  
  .skeleton {
    position: relative;
    overflow: hidden;
    background-color: #ccc;
    border-radius: 4px;
    width: 100%;
  }
  
  .skeleton::after {
    content: '';
    position: absolute;
    top: 0;
    left: -100%;
    height: 100%;
    width: 100%;
    background: linear-gradient(90deg, rgba(255,255,255,0.1) 0%, rgba(255,255,255,0.3) 50%, rgba(255,255,255,0) 100%);
    animation: shimmer 1.5s infinite;
  }
  
  .skeleton-text {
    height: 20px;
    width: 100%;
  }
  
  .skeleton-rect {
    height: 20px;
    width: 100%;
  }
  
  @keyframes shimmer {
    0% {
      left: -100%;
    }
    100% {
      left: 100%;
    }
  }