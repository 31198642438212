.container {
  padding: 20px;
  // height: 100vh;
  hr {
    margin: 20px 0;
  }
  .inputRoomData {
    width: 80px;
    padding: 5px;
    height: 22px;
    border-radius: 4px;
    border: 1px solid #ccc;
    &:focus {
      outline: none;
      border: 1px solid #3968ed;
    }
  }
  .headings {
    color: #333;
    font-family: "Inter";
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 10px;
  }
  .table {
    width: 100%;
    border-collapse: collapse;

    th {
      text-align: left;
      font-weight: 400;
      padding: 5px 0;
      border-bottom: 1px solid #ccc;
    }
    td {
      vertical-align: middle;
      padding: 5px 0;
    }
    .checkbox_td {
      input {
        width: 100px;
        padding: 2px 5px;
        border-radius: 4px;
        border: 1px solid #ccc;
        &:focus {
          outline: none;
        }
      }
    }
  }

  .checkboxContainer{
   label{
    text-transform: capitalize;
   cursor: pointer;
   }
   input{
    cursor: pointer;
   }
  
  }
  





}
.buttonContainer {
  position: sticky;
  bottom: 0;
  padding: 20px;
  background-color: #fff;
}

