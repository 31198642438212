.frontDeskButtonsBookings {
  display: flex;
  height: 30px;
  padding: 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 50px;
  border: 1px solid #3968ed;
  color: #3968ed;
  background: #FFF;
  cursor: pointer;
  width: fit-content;
}

.frontDeskButtonsBookings.active {
    background: #3968ed;
    color: #FFF;
  }


  .currentMonth{
    border: 0.5px solid #ccc;
    padding: 5px;
    border-radius: 5px;
}