.main-container-stay-view {
  width: 100%;
  height: max-content;
  background-color: white;
  /* overflow: auto; */
  padding: 10px;
}

.reservation_view_container {
  border-radius: 10px;
  width: 100%;
  margin: 20px auto;
  background-color: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.booking-table {
  width: 100%;
  border-spacing: 8px 4px;
  /* border-collapse: collapse; */
  background-color: #f0f0f0; /* Change this to the desired color */
  background-clip: padding-box;
  background-origin: padding-box;
  /* table-layout: fixed;  */
  /* border-collapse: collapse; */
}
@media screen and (max-width: 1300px) {
  .booking-table {
    width: max-content;
  }
  .reservation_view_container {
    overflow: scroll;
  }

}
.booking-table tr td {
  padding: 5px 0;
  /* background-color: red; */
}
.reservation_date_headers {
  color: #ffffff;
  font-weight: 500;
  font-size: 12px;
  text-align: center;
  /* padding: 10px 5px; */
  border-radius: 4px;
}
.reservation_date_container {
  background-color: #3968ed;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  padding: 2px 3px;
  cursor: pointer;
  width: 100%;
  height: 40px;
  margin: 0 auto;
}
.reservation_date_container_current_date {
  background-color: #fff;
  color: #3968ed;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  width: 100%;
  height: 40px;
  padding: 2px 3px;
  outline: 1px solid #3968ed;
  margin: 0 auto;
}

.booking-table td {
  /* border: 1px solid black; */
  padding: 8px 5px;
  text-align: center;
}

.room-category {
  text-align: left !important;
  background-color: #ffffff !important;
  border-top: 1px solid #e5e5e5 !important;
  padding: 5px !important;
  font-size: 13px;
  font-weight: 500;
  align-items: center;
}
.unique_id_paragraph_bar {
  font-weight: 500;
  font-size: 10.5px;
  color: #ffffff;
  border-radius: 2px;
  position: relative;
  cursor: pointer;
  min-width: 50px;
  white-space: nowrap;
  text-overflow: clip; /* Prevents text from wrapping to the next line */
  text-overflow: ellipsis; /* Shows the overflow content as ellipsis (...) */
  padding: 0 2px;

}
.tooltip-text-container {
  display: flex;
  justify-content: space-between;
  gap: 20px;
}
.tooltip-text-heading {
  color: #888888;
  font-weight: 400;
  font-size: 10.5px;
}
.tooltip-text-booking-names {
  color: #333333;
  font-weight: 400;
  font-size: 10.5px;
}
.tooltip {
  position: absolute;
  visibility: hidden; /* Change display to visibility */
  transition: opacity 0.4s ease-in-out, visibility 0.4s ease-in-out; /* Transition visibility */
  opacity: 0;
  background: white;
  border: 1px solid #ccc;
  padding: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  width: max-content;
  z-index: 1;
  border-radius: 6px;
  top: 120%; /* Position below the parent */
  left: 50%; /* Center horizontally */
  transform: translateX(-50%);
}
.unique_id_paragraph_bar:hover .tooltip {
  visibility: visible; /* Change display to visibility */
  opacity: 1;
}
.reservation_view_room-category-button,
.reservation_view_next_prev_button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  font-weight: 500;
  font-size: 12px;
}

.button_align_right {
  text-align: right;
}
.button_align_left {
  text-align: left;
}


/* .reservationViewGuestTruncated{
  width: 80px;
  white-space: nowrap;
  overflow: hidden; 
  text-overflow: ellipsis; 
  display: flex;
  margin: 0 auto;
  color: #fff;
} */
