.single_hotel_room_image {
    display: flex;
    justify-content: space-between;
    margin: 5px 0 20px 0;
}

.single_hotel_room_image .single_hotel_room_image_child {
    width: 33%;
    height: 280px;
}

.single_hotel_room_image {
    display: flex;
    flex-wrap: wrap;
}

.single_hotel_room_image_child {
    flex: 1 0 21%;
    margin: 5px;
    cursor: pointer;
}

.lightbox {
    position: fixed;
    z-index: 1000;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.9);
    display: flex;
    justify-content: center;
    align-items: center;
}

.lightbox-content {
    max-width: 80%;
    max-height: 80%;
}

.close {
    position: absolute;
    top: 20px;
    right: 35px;
    color: #fff;
    font-size: 40px;
    font-weight: bold;
    cursor: pointer;
}

.controls {
    position: absolute;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    top: 50%;
    transform: translateY(-50%);
}

.prev,
.next {
    background-color: rgba(0, 0, 0, 0.5);
    color: white;
    border: none;
    font-size: 30px;
    cursor: pointer;
    padding: 16px;
    user-select: none;
}

.prev:hover,
.next:hover {
    background-color: rgba(0, 0, 0, 0.8);
}