.selectdateRange_container {
    
    height: 35px;
    flex-shrink: 0;
    border-radius: 4px;
    border: 0.5px solid #888;
    display: flex;
    padding: 0 10px;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    
}

.selectdateRange_container.disabled {
    cursor: no-drop;
    opacity: 0.8;
}

.selectdateRange_text {
    color: #000000;
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.SelectDateRangeInput .react-datepicker-popper {
    top: 20px;
    left: -15% !important;
}

.SelectDateRangeInput .react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::after {
    top: 0px;
    left: 36px;
}

.SelectDateRangeInput .react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::before {
    top: 0;
    left: 36px;
} 