.hoverOnRoom {
  width: 80px;
  height: 35px;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.3s ease;
}

.hoverOnRoom:hover .hoverOnRoomArrow {
  opacity: 1;
  visibility: visible;
  display: block;
}

.hoverOnRoom:hover {
  cursor: pointer;
}


.hoverOnRoomArrow{
    opacity: 0;
    visibility: hidden;
    display: none;
}
