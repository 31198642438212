.custom-tooltip-wrapper {
    position: relative;
    display: inline-block;
  
  }
  
  .custom-tooltip {
    position: absolute;
    z-index: 0;
    font-size: 12px;
    font-weight: 500;
    background: #ffffff;
    color: #1f2124;
    padding: 5px 8px;
    border-radius: 5px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    margin-top: 4px;
    pointer-events: none;
    transition: all 0.3s cubic-bezier(0.68, -0.55, 0.265, 1.55);
    width: auto; 
    white-space: nowrap; 
  }
  

  .custom-tooltip-above::after {
    position: absolute;
    content: "";
    height: 11px;
    width: 11px;
    background: #ffffff;
    bottom: -6px;
    left: 50%;
    transform: translate(-50%) rotate(45deg);
    transition: all 0.3s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  }

 .custom-tooltip-below::after {
  position: absolute;
  content: "";
  height: 11px;
  width: 11px;
  background: #ffffff;
  border-width: 1px;
 
  top: -6px;
  left: 50%;
  transform: translate(-50%) rotate(45deg);
  transition: all 0.3s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

  
  .custom-tooltip-above {
    bottom: calc(100% + 8px); 
    left: 50%;
    transform: translateX(-50%);
  }
  
  .custom-tooltip-below {
    top: calc(100% + 8px); 
    left: 50%;
    transform: translateX(-50%);
  }

  .custom-tooltip-left::after,
.custom-tooltip-right::after {
  position: absolute;
  content: "";
  height: 11px;
  width: 11px;
  background: #ffffff;
  transition: all 0.3s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.custom-tooltip-left::after {
  right: -6px;
  top: 50%;
  transform: translateY(-50%) rotate(45deg);
}

.custom-tooltip-right::after {
  left: -6px;
  top: 50%;
  transform: translateY(-50%) rotate(45deg);
}

.custom-tooltip-left {
  right: calc(100% + 8px);
  top: 50%;
  transform: translateY(-50%);
}

.custom-tooltip-right {
  left: calc(100% + 8px);
  top: 50%;
  transform: translateY(-50%);
}
  