.mainWrapper {
    background-color: #FFFFFF;
    border-radius: 8px;

    .sliderTitle {
        display: flex;
        justify-content: space-between;
        padding: 10px 15px;
        width: 100%;
        border-bottom: 1px solid #CCCCCC;

        h4 {
            font-style: italic;
        }
    }

    .sliderContent {
        height: 80%;
        overflow: scroll;
        padding: 10px 15px;
    }

    &.show {
        animation: slideIn 1s cubic-bezier(0, -0.01, 0.12, 1.33) forwards;
        transform: translateX(0);
    }

    &.hide {
        animation: slideOut 5s cubic-bezier(0, -0.01, 0.12, 1.33) forwards;
        transform: translateX(100%);
    }

    @keyframes slideIn {
        from {
            transform: translateX(100%);
        }

        to {
            transform: translateX(0);
        }
    }

    @keyframes slideOut {
        from {
            transform: translateX(0);
        }

        to {
            transform: translateX(100%);
        }
    }

}

.mainWrapperTable {
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;

    .sliderTableContent{
        padding: 0 !important;
    }
}