.truncatedTextContainer {
  position: relative;
  width: fit-content;
  font-family: 'Inter';
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.truncatedTextContainer_text {
  white-space: nowrap;
  overflow: hidden;
  width: 400px;
  text-overflow: ellipsis;
  cursor: pointer;
  text-align: left;
  z-index: 0;
}

.truncatedTextContainer_text_tooltip {
  position: absolute;
  display: none;
  opacity: 0;
  transition: opacity 1s ease;
  padding: 5px;
  border-radius: 4px;
  box-shadow: 0px 0px 5px rgba(2, 2, 111, 0.4);
  text-align: left;
  width: max-content;
  background-color: #fff;
  z-index: 10000000;
  overflow: auto;
}

.truncatedTextContainer_text:hover + .truncatedTextContainer_text_tooltip {
  display: block;
  opacity: 1;
  transition: opacity 0.1s ease-in-out;
}
