@import "../../Components//NewCustomComp/settings/globalMixins.scss";
.container {
  width: 100%;
  height: 500px;
  overflow-y: scroll;
  padding-right: 5px;
  @include scrollbar-styling;
  position: relative;
}

.table {
  width: 100%;
  border-collapse: collapse;

  th {
    text-align: left;
    border-bottom: 1px solid #ccc;
    font-size: 12px;
    padding: 5px;
    &:first-child {
      padding: 5px 0;
    }
  }

  td {
    text-align: left;
    padding: 5px;
    &:first-child {
      padding: 5px 0;
    }
  }
  tr {
    border-bottom: 1px solid #ccc;
    &:last-child {
      border-bottom: none;
    }
  }
}
.headings {
  font-weight: 600;
  margin-top: 15px;
  margin-bottom: 10px;
  font-size: 15px;
}
.selectTagContainer {
  select {
    border: 1px solid #adadad;
    font-family: "Inter", sans-serif;
    font-weight: 400;
    border-radius: 4px;
    color: #333333;
    cursor: text;
    width: 100%;
    font-size: 12px;
    padding: 3px 10px;
    cursor: pointer;
    &:focus {
      border: none;
      outline: 1px solid #3968ed;
    }
  }
}
.buttonContainer {
  position: sticky;
  width: 100%;
  bottom: -1px;
  padding-top: 5px;
  background-color: #fff;
  z-index: 10000;
  box-shadow: 0 -2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  margin-top: 10px;
}
.noAddonsContainer {
  text-align: center;
  padding: 10px;
  background-color: #f9f9f9;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  margin-top: 10px;
}

.noAddonsMessage {
  svg {
    fill: #858585;
  }
}

.noAddonsMessage {  
  p {
    font-size: 14px;
    color: #666;
  }
}
