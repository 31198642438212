.step-indicator {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .step-container {
    display: flex;
    align-items: center;
    width: max-content;
    padding-left: 10px;
    &:first-of-type {
      padding-left: 0;
    }
  }
  .step-line {
    height: 1px;
    flex-grow: 1;
    background-color: #3968ed;
  }
  .step {
    display: flex;
    align-items: center;
  
    .step-icon {
      width: 30px;
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      margin-right: 10px;
      position: relative;
    }
  
    .step-label {
      font-family: "Inter";
      font-size: 14px;
      font-weight: 500;
      text-align: left;
      color: #333;
      text-transform: capitalize;
    }
  
    .step-description {
      font-family: "Inter";
      font-size: 12px;
      font-weight: 400;
      line-height: 15px;
      text-align: left;
      color: #858585;
    }
  }
}


// vertical step indicator styling


.step-indicator-vertical {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    width: fit-content;
   
   
    .singleStepContainer{
        height: 110px;
        position: relative;
    }
    
    .step-container {
      display: flex;
      width: max-content;
      
    }
    .step-line {
      position: absolute;
      width: 1px;
      background-color: #3968ed;
      height: 80%;
      left: 15px;
    }
    .step {
      display: flex;
      align-items: center;
    
      .step-icon {
        width: 30px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        margin-right: 10px;
        position: relative;
      }
    
      .step-label {
        font-family: "Inter";
        font-size: 14px;
        font-weight: 500;
        text-align: left;
        color: #333;
        text-transform: capitalize;
      }
    
      .step-description {
        font-family: "Inter";
        font-size: 12px;
        font-weight: 400;
        line-height: 15px;
        text-align: left;
        color: #858585;
      }
    }
  }










