/* input {
  width: 100%;
} */

#foodBillContent {
  padding: 20px;
  background-color: #fff;
  border-radius: 8px;
}

@media print {
  .hideOnPrint {
    display: none;
  }
}
