.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  // background: red;

  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999999;
}

.popup-container {
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
  width: 400px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  position: relative;
}

.popup-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #eee;
  padding-bottom: 10px;
  margin-bottom: 20px;
}

.popup-header  {
  h2{
    margin: 0;
    font-size: 18px;
    color: #333;
    font-weight: 500;
    font-family: "Inter";
  }
  
}

.close-btn {
  background: transparent;
  border: none;
  cursor: pointer;
  font-size: 18px;
}

.popup-content {
  margin-bottom: 20px;
  font-size: 16px;
}
.popup-content  {
  p{
    text-align: center;
    font-size: 14px;
    font-family: "Inter";
    color: #858585;
  }
  
}
.popup-content  {
  h3{
    margin: 10px 0;
    font-size: 20px;
    color: #333;
    text-align: center;
    font-family: "Inter";
  }

}

.popup-actions {
  display: flex;
  gap: 40px;
  width: fit-content;
  margin: 0 auto;
}

.popup-btn-whatsapp {
  transition: background-color 0.3s ease, box-shadow 0.3s ease,
    transform 0.3s ease;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  border: none;
  height: 45px;
  width: 45px;
  border-radius: 50%;
  background-color: #25d366;
  position: relative;
  cursor: pointer;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.4), 0px 0px 3px rgba(0, 0, 0, 0.4); /* 3D effect */

  svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &:hover {
    box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.4), 0px 3px 6px rgba(0, 0, 0, 0.2); /* Deeper shadow on hover */
    transform: translate(-2px, -2px);
  }
}
.popup-btn-email {
  transition: background-color 0.3s ease, box-shadow 0.3s ease,
    transform 0.3s ease;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  border: none;
  height: 45px;
  width: 45px;
  border-radius: 50%;
  background-color: #dc3545;
  position: relative;
  cursor: pointer;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.4), 0px 0px 3px rgba(0, 0, 0, 0.4); /* 3D effect */

  svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &:hover {
    box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.4), 0px 3px 6px rgba(0, 0, 0, 0.2); 
    transform: translate(-2px, -2px);
   
  }
}
.containerShareButton{
  button{
    background: transparent;
    border: none;
    cursor: pointer;
    font-size: 14px;
    width: fit-content;
  }
}