

.chefDashboardKotContentEditButton button{
    border: 0;
    background: transparent;
    cursor: pointer;
    font-size: 16px;
    font-weight: 500;
    color: #3968ed;
}
.chef_dashboard_items_list{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
.chef_dashboard_items_list_mid{
    border-left: 1px solid #fff;
    padding: 0px 20px;
    height: 80px;
    border-right: 1px solid #fff;
}
.chefDashboardKotType{
    font-size: 18px;
}
.chefDashboardKotTime{
    font-size: 16px;
}
.chefDashboardKotContent{
    background-color: #ddd;
    margin: 10px 20px;
    padding: 10px;
    border-radius: 4px;
}
.chefDashBoardCancelButton{
    background-color: #fff !important;
    border: 1px solid #E03838 !important;
    color:#E03838 !important;
}
/* .............................................. */
.chefDashboardContainer {
    border-radius: 20px;
    /* background: #eff0f2; */
    padding: 10px;
    overflow-x: auto;
  }
  .chefDashboardHeading {
    color: #333;
    font-family: "Inter";
    font-size: 25px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin: 5px 0 10px 0;
    padding: 0;
  }
  
  .chefDashboardCardsContainer {
    display: flex;
    gap: 10px;
    overflow-x: auto;
    padding: 0 0 20px 0;
  
  }
  .chefDashboardCardsContainer::-webkit-scrollbar {
    height: 8px;
  }
  
  .chefDashboardCardsContainer::-webkit-scrollbar-thumb {
    background-color: #888; 
    border-radius: 6px; 
  }
  
  /* Firefox */
  .chefDashboardCardsContainer {
    scrollbar-width: thin; 
  }
  
  .chefDashboardCardsContainer {
    scrollbar-color: #888 #f0f0f0;
  }
  
  /* Optional: Style the scrollbar track */
  .chefDashboardCardsContainer::-webkit-scrollbar-track {
    background-color: #f0f0f0; 
  }
  
  .chefDashboardCardsContainer::-webkit-scrollbar-track:hover {
    background-color: #e0e0e0; 
    cursor: pointer;
  }
  .chefDashboardCards {
    width: 340px;
    flex-shrink: 0;
    height: fit-content;
    border-radius: 10px;
    background: #fff;
  }
  .chefDashboardCardHeader {
    padding: 0px 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 10px 10px 0 0;
    height: 80px;
  }
  .chefDashboardKotType,
  .chefDashboardKotTime {
    color: #fff;
    font-family: "Inter";
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin: 0;
    padding: 0;
  }
 
  .chefDashboardCardCategory {
    padding: 10px 20px;
    text-transform: capitalize;
    background: #ddd;
    /* text-transform: capitalize; */
    color: #333;
    font-family: "Inter";
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  .chefDashboardCardProduct {
    padding: 10px 20px;
  }
  .chefDashboardCardProductName {
    color: #333;
    font-family: "Inter";
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin: 0;
    padding: 0;
  }
  .addBackgroundColorToHeaderOne {
    background: #15aa12;
  }
  .addBackgroundColorToHeaderTwo {
    background: #e8b41a;
  }
  .addBackgroundColorToHeaderThreeAndFour {
    background: #3968ed;
  }
  
  .chefDashboardCardFooter {
    padding: 10px 20px;
  }
  .chefDashboardCardButton {
    cursor: pointer;
    border: none;
    width: 100%;
    border-radius: 50px;
    padding: 10px 0;
    color: #fff;
    font-family: "Inter";
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  