.folioSummeryBill{
    display: flex;
    justify-content: center;
    gap: 20px;
  }
  .folioSummeryBillContainer {
    width: 400px;
    border-radius: 8px;
    background-color: #fff;
    padding: 10px;
  
  }
  .folioSummeryTable {
    border-collapse: collapse;
    font-family: "Inter";
    width: 100%;
    border-radius: 8px;
  }
  .folioSummeryTableHeading {
    text-align: left;
    font-weight: 600;
    font-size: 16px;
    padding: 5px 0;
  }
  .folioSummeryTableHeaderButton {
    background-color: transparent;
    border: none;
    cursor: pointer;
    font-weight: 500;
    font-size: 12px;
    display: flex;
    align-items: center;
    gap: 5px;
    color: #3968ed;
    width: fit-content;
    margin: auto 0 auto auto;
  }
  .folioSummeryTableData {
    font-weight: 400;
    font-size: 14px;
    padding: 5px 0;
  }
  .folioSummeryTableDataRight {
    text-align: right;
  }
  
  .folioSummeryBillInvoiceSettings{
    border-radius: 8px;
    background-color: #fff;
    padding: 20px;
    /* width: 300px; */
    height: fit-content;
  }
  
  .folioSummeryBillInvoiceSettingsHeading{
    font-weight: 600;
    font-size: 16px;
    font-family: "Inter";
   
  }
  .folioSummeryBillInvoiceSettingsLabel{
    font-weight: 400;
    color: #333333;
    font-family: "Inter";
    font-size: 12px;
    cursor: pointer;
  }
  .folioSummeryBillInvoiceSettingsCheckbox{
    margin-right: 10px;
    height: 12px;
    margin: 10px 5px 10px 0;
    cursor: pointer;
  }