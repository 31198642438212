.agent-cards-parent{ 
  width: 100%;
  /* background-color: #fff; */

  display: flex;
  /* justify-content: center; */
  /* align-items: center; */
  flex-wrap: wrap;
  flex-direction: column;
  gap: 10px;
}


.agent-card{
  flex-basis: 10%;
  padding: 15px;
  background-color: #fff;
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  border-radius: 8px;
}

.inner-heading-faded{
  color: #888;
font-family: Inter;
font-size: 12px;
font-style: normal;
font-weight: 500;
line-height: 130%; /* 15.6px */
}


.important-bold{
  color: #333;
font-family: Inter;
font-size: 16px;
font-style: normal;
font-weight: 600;
line-height: normal;
}