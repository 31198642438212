.description_parent {
  display: flex;
  align-items: center;
  gap: 10px;
}

.positive_background {
  border-radius: 4px;
  background: linear-gradient(90deg, #15AA12 0%, #6BE269 100.5%);
  min-height: 90px;
  padding: 10px;
  display: flex !important;
  align-items: center;
}

.positive_background p, .negative_background p {
  color: #ffffff;
  font-family: "Inter";
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
}

.negative_background {
  border-radius: 4px;
  background: linear-gradient(90deg, #E03838 0%, #FF9292 100.5%);
  /* background: #ffeaea; */
  min-height: 60px;
  padding: 10px;
  display: flex !important;
  align-items: center;
}

.suggestion_slider .slick-dots {
  bottom: 82px;
  right: 0;
  text-align: end !important;
}

.suggestion_slider .slick-dots .slick-active button::before,
.housekeeping_suggession_slider .slick-dots .slick-active button::before {
  color: #3968ED;
}

.suggestion_slider .slick-dots li,
.housekeeping_suggession_slider .slick-dots li {
  width: 10px;
  height: 10px;
  margin: 0 3px;
}

.suggestion_slider .slick-dots li button::before,
.housekeeping_suggession_slider .slick-dots li button::before {
  /* width: 10px;
  height: 10px; */
  color: #545454;
  ;
  font-size: 8px;
}

.suggestion_slider .slick-dots li button,
.housekeeping_suggession_slider .slick-dots li button {
  padding: 0;
  width: 10px;
  height: 10px;
}


.housekeeping_suggession_slider {
  margin-top: 20px;
}


/* Suggession slider */

.housekeeping_suggession_slider .slick-dots {
  top: -42px;
  right: 0;
  text-align: end !important;
  height: fit-content;
}