
    .searchContainer {
      display: flex;
      align-items: center;
      border: 1px solid #ccc;
      border-radius: 4px;
      padding: 2px 5px;
      width: 100%;
      
    }
 
    .icon {
      margin-right: 5px;
      font-size: 14px;
      color: #888;
    }
    .input {
      border: none;
      outline: none;
      width: 100%;
      font-size: 12px;
      
    }
    .searchContainer:focus-within {
      border: 1px solid #3968ed;
    }