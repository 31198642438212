.auditStatusContainer {
  width: fit-content;
  height: fit-content;
  display: flex;
  align-items: center;
  gap: 5px;
  .statusText {
    text-transform: capitalize;
    font-size: 14px;
    font-weight: 400;
    color: #333;
    font-family: "Inter";
  }
  .statusDot {
    width: 8px;
    height: 8px;
    border-radius: 50%;
  }
  .confirm {
    background-color: #2db232;
  }
  .submit {
    background-color: #3968ed;
  }
  .pending {
    background-color: #deaf21;
  }
  .cancel {
    background-color: #ed3939;
  }
  .draft {
    background-color: #858585;
  }
}
