.breadCrumbContainer {
  width: fit-content;
  padding: 5px;
  border-radius: 4px;
  &.withBorder {
    border: 1px solid #d6d6d6;
  }

  ol {
    display: flex;
    list-style-type: none;
    padding: 0;
    align-items: center;
    gap: 5px;
    li {
      display: flex;
      align-items: center;
      gap: 5px;
    }
    a {
      font-family: "Inter";
      font-size: 14px;
      font-weight: 500;
      text-align: left;
      color:#858585;
      text-transform: capitalize;
      &:hover {
        text-decoration: underline;
        color: #333;
      }
    }
    .lastLink {
      font-family: "Inter";
      font-size: 14px;
      font-weight: 500;
      text-align: left;
      color: #333;
      text-transform: capitalize;
    }
  }
}
