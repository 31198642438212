.create_booking_page_card_bottom{
    background-color: transparent;
    border: none;
}
.new_createBooking_card_extra_adult_child_container_scroll {
    padding: 0;
    margin: 0;
    overflow-x: auto;
}

.new_createBooking_card_extra_adult_child_container_scroll::-webkit-scrollbar {
    height: 4px;
}

.new_createBooking_card_extra_adult_child_container_scroll::-webkit-scrollbar-track {
    background: #f1f1f1; /* color of the track */
}

.new_createBooking_card_extra_adult_child_container_scroll::-webkit-scrollbar-thumb {
    background: #888; /* color of the scrollbar handle */
}

.new_createBooking_card_extra_adult_child_container_scroll::-webkit-scrollbar-thumb:hover {
    background: #555; /* color of the scrollbar handle on hover */
}

.extra_adults_new_input{
   height: 25px;
}


