.dateRangePickerContainer{
    background-color: #fff;
    border: 1px solid #ccc;
    padding: 5px;
    border-radius: 4px;
    width: fit-content;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    position: relative;
  

    button{
        background-color: transparent;
        border: none;
    }
}

