.container {
  position: relative;
  width: 90px;
  height: 90px;
  .uploadContainer {
    width: 100%;
    height: 100%;
    border: 1px dashed #ccc;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    .uploadLabel {
      display: flex;
      flex-direction: column;
      align-items: center;
      cursor: pointer;
      font-family: "Inter";
      font-size: 12px;
    }
  }
}

.uploadInput {
  display: none;
}

.imageContainer {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f0f0f0;
  overflow: hidden;
  border-radius: 4px;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1),
    /* Bottom shadow */ 0 -1px 2px 0 rgba(0, 0, 0, 0.1),
    /* Top shadow */ 1px 0 2px 0 rgba(0, 0, 0, 0.1),
    /* Right shadow */ -1px 0 2px 0 rgba(0, 0, 0, 0.1); /* Left shadow */
  cursor: pointer;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 4px;
  }
}

.buttonContainerOverlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  display: none;
  button {
    width: fit-content;
    height: fit-content;
    background-color: transparent;
    border: none;
    cursor: pointer;
  }
}

.imageContainer:hover .buttonContainerOverlay {
  display: flex;
}

.imagePreview {
  max-width: 100%;
  max-height: 100%;
}

.deleteButton,
.previewButton {
  position: absolute;
  background: transparent;
  border: none;
  cursor: pointer;
  font-size: 20px;
  color: #fff;
}

.deleteButton {
  top: 10px;
  right: 10px;
}

.previewButton {
  bottom: 10px;
  right: 10px;
}

.previewPopup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 100000;
  .previewCloseButton {
    position: absolute;
    top: 10px;
    right: 10px;
    background-color: transparent;
    border: none;
    cursor: pointer;
    font-size: 20px;
    color: #fff;
  }
}

.previewImage {
  max-width: 90%;
  max-height: 90%;
}

.uploadingText {
  font-family: "Inter";
  font-size: 12px;
  font-weight: 400;
  line-height: 15px;
  text-align: center;
  color: #858585;
  padding-top: 5px;
  text-wrap: nowrap;
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: center;
  span {
    color: red;
  }
}
