.add_property_main {
    width: 650px;
    height: 100%;
    background-color: white;
    padding: 20px;
}

.add_property_main_heading {
    font-size: 18px;
    font-weight: 600;
}
.add_property_step .add_property_step_child {
    padding: 10px 0;
    height: 60px;
}