/* Style for Simple Pagination */
.simple {
  display: flex;
  gap: 0px;
  button {
    font-family: "Inter";
    font-size: 14px;
    font-weight: 400;
    border-radius: 4px;
    padding: 10px;
    background-color: #ffffff;
    border: 1px solid #d6d6d6;
    cursor: pointer;
    &:hover{
      background-color:  rgba(57, 105, 237, 0.705);
      color: white;
    };
    &:first-of-type{
      border-right: none;
      border-radius: 4px 0 0 4px;
    }&:last-of-type{
      border-radius: 0px 4px 4px 0;
    }
  }
}

/* Style for Basic Pagination */
.basic {
  
    button {
      font-family: "Inter";
      font-size: 14px;
      font-weight: 400;
      padding: 10px;
      background-color: #ffffff;
      border: 1px solid #d6d6d6;
      border-left: none;
      cursor: pointer;
      &:hover{
        background-color: rgba(57, 105, 237, 0.705);
        color: white;
      }
      &.active{
        background-color: #3968ed;
        color: white;
      }
    }

    button:first-child{
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
      border-left: 1px solid #d6d6d6;
    }
    button:last-child {
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
    }
  
}

/* Style for Advanced Pagination */
.advance {
  
  button {
    font-family: "Inter";
    font-size: 14px;
    font-weight: 400;
    padding: 10px;
    background-color: #ffffff;
    border: 1px solid #d6d6d6;
    border-left: none;
    cursor: pointer;
    &:hover{
      background-color:  rgba(57, 105, 237, 0.705);
      color: white;
    }




    &.jumpFirstButton {
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
      border: 1px solid #d6d6d6;
      
    };
    &.jumpLastButton{
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
      border: 1px solid #d6d6d6;
      border-left: none
    }

    &:last-of-type{
      border: 1px solid #d6d6d6;
      border-radius: 4px;
    }
    &.active{
      background-color: #3968ed;
      color: white;
    }
  };
  span{
    padding:  10px;
    letter-spacing: 3px;
    border-right: 1px solid #d6d6d6;
    border-top: 1px solid #d6d6d6;
    border-bottom: 1px solid #d6d6d6;
  }

  input{
    border: 1px solid #d6d6d6;
   border-radius: 4px;
    width: 40px;
    padding: 10px 5px;
    margin: 0 5px 0 10px;
    &:focus{
      outline: 1px solid #3968ed;
    }
  }


}
