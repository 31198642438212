.current_month_btn {
    color: #333;
    font-family: "Inter";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    border-radius: 4px;
    border: 0.5px solid #CCC;
    background: #FFF;
    padding: 10px;
}

.kot_table {
    display: flex;
    width: 35px;
    height: 35px;
    padding: 10px;
    justify-content: center;
    align-items: center;
    background: transparent;
    color: #E8B41A;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 19px;
}

.kot_table.occupied_table {
    border-radius: 4px;
    border: 1px solid #E8B41A;
    color: #E8B41A;
    cursor: pointer;
}

.kot_table.vacant_table {
    border-radius: 4px;
    border: 1px solid #15AA12;
    color: #15AA12;
}

.available_table_dot {
    width: 8px;
    height: 8px;
    background: #15AA12;
    border-radius: 50%;
    display: block;
}

.occupied_table_dot {
    width: 8px;
    height: 8px;
    background: #E8B41A;
    border-radius: 50%;
    display: block;
}




/* 
 */

 /*  */
 /*  */

 .statusDeliveryToggleContainer {
    position: relative;
    cursor: pointer;
    background-color: blanchedalmond;
    width: fit-content;
    padding: 6px;
    border-radius: 10px;
  }
  .statusDeliveryMenu {
    position: absolute;
    top: 30px;
  }
  
  .statusDeliveryText{
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    color: black;
  }
  .statusDeliveryTextOptions{
    padding: 5px 0;
  }