.dashboard_page{
    width: 100%;
}
.dashboard_page_left{
    width: 70%;
}
.F_and_b_dashBoard_container{
    background-color: white;
    padding: 20px;
    border-radius: 4px;
   
    margin-bottom: 20px;
}
.dashboard_page_right{
    width: 30%;
    margin: 0 !important;;
}
.orders_rate{
    background-color: #ffff;
    border-radius: 6px;
}
.new_order_button{
    position: relative;
}
.new_order_popup_container{
    position: absolute;
    left: 40px;
    background-color: #fff;
}
.new_order_popup_content{
    display: flex;
    justify-content: space-between;
    gap: 10px;
}
.new_order_table{
    color: black;
    padding: 0px 20px;
    border-radius: 6px;
    cursor: pointer;
}
.new_order_table ,.new_order_room{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.area_container{
    background-color:#15AA12;
    padding: 12px 28px;
    border-radius: 6px;
    color: white;
    cursor: pointer;
}
.area_list_parent{
    padding: 20px 0px;
}