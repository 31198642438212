.dashboard-heading {
    /* color: #252525;
font-family: Inter;
font-size: 18px;
font-style: normal;
font-weight: 600;
line-height: 130%; 23.4px */

    color: #333;
    font-family: "Inter";
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.currentMonth{
    border: 0.5px solid #ccc;
    padding: 5px;
    border-radius: 5px;
}