@import "../settings/_settings";
@import "../settings/globalMixins";
.searchContainer {
  background-color: #fff;
  width: 100%;
  border: 1px solid #ccc;
  display: flex;
  align-items: center;
  border-radius: 4px;
  padding: 2px 0 2px 10px;

  .searchIcon {
    width: 20px;
    svg {
      vertical-align: middle;
    }
  }
  input {
    width: calc(100% - 20px);
    height: 100%;
    border: none;
    &:focus {
      outline: none;
    }
  }
  &.small {
    font-size: 12px;
    height: 23px;
   
  }
  &.medium {
    font-size: 14px;
    height: 29px;
   
  }
  &.large {
    font-size: 16px;
    height: 35px;
    
  }
  &.disabled {
    background-color: $disabled-input-bg-color;
    border: 1px solid $primary-btn-disabled-color;
    color: #858585;

    cursor: not-allowed !important;
    &:focus {
      border-color: $primary-btn-disabled-color;
    }
  }
  &.error {
    border-color: red;
    background-color: #FFEAEA;

    input {
      background-color: #FFEAEA;
    }
  }
}

.error_text {
  font-family: "Inter";
  font-size: 10px;
  font-weight: 400;
  line-height: 12px;
  color: $error-color;
  display: block;
  margin-top: 3px;
  color: red;
}

.dropdownContainer {
  position: absolute;
  top: 100%;
  width: 100%;
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 4px;
  z-index: 1000;
  max-height: 200px;
  overflow-y: auto;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  @include scrollbar-styling;
}

.dropdownItem {
  padding: 5px;
  cursor: pointer;
  &:hover {
    background-color: #f1f1f1;
  }

  &.focused {
    background-color: #f1f1f1;
  }
}

.dropdownItemMultiple {
  display: flex;
  gap: 5px;
  align-items: center;
}
.label{
  height: fit-content;
  @include label-styling;
}