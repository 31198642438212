.selectDayButtons {
  background-color: white;
  color: #888888;
  border: 1px solid #888888;
  margin-right: 10px;
  cursor: pointer;
  border-radius: 50px;
  outline: none;
  height: 35px;
  width: 80px;
  display: grid;
  place-items: center;
  font-size: 12px;
  font-family: "Inter";
  font-weight: 500;
  transition: transform 0.3s ease-in-out;
  transform-origin: center;

}

.selected {
  color: #3968ed;
  border: 1px solid #3968ed;
}

.selectDayButtons:hover {
  transform: scale(1.04);
}


.dayName {
  text-transform: capitalize;
  cursor: pointer;
  input {
    margin-right: 4px;
    font-size: 14px;
    cursor: pointer;
    height: 15px;
    width: 15px;

  }
}
