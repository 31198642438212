.spinner {
  border: 4px solid white; 
  border-top-color: #3968ed; 
  border-radius: 50%;
  width: 100%;
  height: 100%;
  animation: spin 1.2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}