.container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  gap: 10px;
  position: relative;
  cursor: pointer;
  .property_name {
    font-family: "Inter";
    font-weight: 600;
    color: #3968ed;
    font-size: 16px;
    max-width: 150px;      
    white-space: nowrap;     
    overflow: hidden;        
    text-overflow: ellipsis; 
  }

  
  
}
.iconContainer {
  transform: rotate(0deg);
  transition: transform 0.3s ease-in-out;
}

.rotate {
  transform: rotate(90deg);
  transition: transform 0.3s ease-in-out;

}

.user_profile_dropdown_content {
  position: absolute;
  right: 5px;
  min-width: max-content; 
  max-width: 100%; 
  top: 55px;
  z-index: 20;
  border-radius: 4px;
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.5), 0px 0px 3px 0 rgba(0, 0, 0, 0.5);
  background: white;
  padding: 5px 10px;
 
  &.small{
    top: 20px;
    top: 41px;
    width: 100%;
  }


}

// .user_profile_options_list li,
.user_profile_options_list{
  .user_name{
    font-style: italic;
    cursor: pointer;
  }
  .links{
    color: #333;
    cursor: pointer;
    a{
      color: #333;
    }
  }

  
}


