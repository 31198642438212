@import "../settings/globalMixins";

.container {
  position: relative;
  width: 414px;
  height: 170px;
  box-shadow: 0px 4px 8px 0px hsla(0, 0%, 0%, 0.329);
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  border-radius: 4px;

  .uploadContainer {
    width: 90px;
    height: 80px;
    border: 1px dashed #ccc;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    .uploadLabel {
      display: flex;
      flex-direction: column;
      align-items: center;
      cursor: pointer;
      font-family: "Inter";
      font-size: 12px;
    }
  }
}

.uploadInput {
  display: none;
}

.imageContainer {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f0f0f0;
  overflow: hidden;
  border-radius: 4px;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1),
    /* Bottom shadow */ 0 -1px 2px 0 rgba(0, 0, 0, 0.1),
    /* Top shadow */ 1px 0 2px 0 rgba(0, 0, 0, 0.1),
    /* Right shadow */ -1px 0 2px 0 rgba(0, 0, 0, 0.1); /* Left shadow */
  cursor: pointer;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 4px;
  }
}

.buttonContainerOverlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  display: none;
  button {
    width: fit-content;
    height: fit-content;
    background-color: transparent;
    border: none;
    cursor: pointer;
  }
}

.previewContainer {
  width: 100%;
  padding: 20px;
  height: 100%;
  display: flex;
  align-items: center;
  gap: 10px;
  flex-wrap: wrap;
  overflow-y: auto;
  @include scrollbar-styling;
  .previewItem {
    position: relative;
    width: 80px;
    height: 100px;
    border-radius: 4px;

    div {
      height: 80px;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 4px;
      }
      button {
        position: absolute;
        top: -5px;
        right: -5px;
        cursor: pointer;
        border: none;
        background-color: transparent;
      }
    }
    p {
      font-size: 12px;
      width: 100%;
      text-align: center;
      color: #858585;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}

.uploadingText {
  font-family: "Inter";
  font-size: 12px;
  font-weight: 400;
  line-height: 15px;
  text-align: center;
  color: #858585;
  padding-top: 5px;
  text-wrap: nowrap;
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: center;

  span {
    color: red;
  }
}

.loadingContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px; // Adjust height as needed
  width: 100%;
}

.dots {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.dot {
  width: 6px; // Adjust size as needed
  height: 6px; // Adjust size as needed
  border-radius: 50%;
  background-color: #3968ed; // Adjust color as needed
  animation: bounce 0.6s infinite alternate;
}

.dot:nth-child(2) {
  animation-delay: 0.2s; // Delay for second dot
}

.dot:nth-child(3) {
  animation-delay: 0.4s; // Delay for third dot
}

@keyframes bounce {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.5); // Adjust scale as needed
  }
}