@mixin scrollbar-styling {
  &::-webkit-scrollbar {
    width: 4px;
    height: 4px;
    // padding-top: 10px;
  }

  &::-webkit-scrollbar-track {
    background: #fff;
    border: 0.4px solid #ccc;
    border-radius: 8px;
  }

  &::-webkit-scrollbar-thumb {
    background: #c6c6c6;
    border-radius: 8px;
    margin: 2px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #8f8b8b;
  }

  &::-webkit-scrollbar-corner {
    background: #fff;
  }
}
@mixin overlay-scrollbar {
  // Container styling
  overflow-y: overlay; 
  // padding-right: 0; 

  &::-webkit-scrollbar {
    width: 4px; 
    height: 4px; 
  }

  &::-webkit-scrollbar-track {
    background: rgba(0, 0, 0, 0.1); // Semi-transparent track
    border-radius: 5px; 
    // margin: 5px 0; 
  }

  &::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, 0.3); // Semi-transparent thumb
    border-radius: 10px; // Rounded corners for the thumb
    border: 2px solid transparent; // Transparent border for smooth edges
    background-clip: content-box; // Ensure the background doesn't cover the border
  }

  &::-webkit-scrollbar-thumb:hover {
    background: rgba(0, 0, 0, 0.2); // Darker color on hover
  }

  &::-webkit-scrollbar-corner {
    background: transparent; // Transparent corner for a clean look
  }
}

@mixin label-styling {
  color: #333;
  white-space: nowrap;

  &.large {
    font-size: 16px;
    margin-bottom: 5px;
  }

  &.medium {
    font-size: 14px;
    margin-bottom: 3.5px;
  }

  &.small {
    font-size: 12px;
    margin-bottom: 2.5px;
  }
}
