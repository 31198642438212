.settlementTable {
  background-color: #f5f5f5;
  width: 100%;
  padding: 5px;
  color: #666666;
  border-collapse: collapse;

  th {
    text-align: left;
    font-weight: 400;
    padding-bottom: 5px;
    border-bottom: 1px solid #939393;
  }
  td {
    padding: 3px 0;
  }
}
