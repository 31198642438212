@keyframes slideInBounceTop {
  0% {
    transform: translateY(calc(-100% - 250px));
    opacity: 0.8; /* Initial opacity */
  }
  20% {
    transform: translateY(10px);
  }
  40% {
    transform: translateY(-5px);
  }
  60% {
    transform: translateY(5px);
  }
  80% {
    transform: translateY(-2px);
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes slideInBounceBottom {
  0% {
    transform: translateY(calc(100% + 250px));
    opacity: 0.8; /* Initial opacity */
  }
  20% {
    transform: translateY(-10px);
  }
  40% {
    transform: translateY(5px);
  }
  60% {
    transform: translateY(-5px);
  }
  80% {
    transform: translateY(2px);
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes slideOutTop {
  0% {
    transform: translateY(0);
    opacity: 1;
  }
  100% {
    transform: translateY(calc(-100% - 250px));
    opacity: 1;
  }
}

@keyframes slideOutBottom {
  0% {
    transform: translateY(0);
    opacity: 1;
  }
  100% {
    transform: translateY(calc(100% + 250px));
    opacity: 1;
  }
}

.toast {
  position: absolute;
  margin: 10px;
  width: 300px !important;
  padding: 9px 15px;
  border-radius: 5px;
  background-color: #ffffff;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  color: #000;
  display: flex;
  justify-content: space-between;
  z-index: 999999;
  align-items: center;
  opacity: 1;
}

.toast.show.top {
  animation: slideInBounceTop 0.6s ease-out forwards;
}

.toast.show.bottom {
  animation: slideInBounceBottom 0.6s ease-out forwards;
}

.toast.hide.top {
  animation: slideOutTop 0.9s ease-out forwards;
}

.toast.hide.bottom {
  animation: slideOutBottom 0.9s ease-out forwards;
}

.toast-content {
  display: flex;
  align-items: center;
}

.toast-icon {
  margin-right: 10px;
  margin-top: 4px;
}

.tool-tip {
  position: relative;
  z-index: 1;
}

.toast-message {
  width: auto; /* Let the width adjust automatically */
  max-width: 220px; /* Set a maximum width for the toast container */
  flex: 1;
  text-align: start;
  font-size: 14px;
  word-wrap: break-word;
  overflow-wrap: break-word;
}

.progress-bar {
  position: absolute;
  bottom: 0;
  left: 0;
  height: 5px;
  background: rgba(255, 255, 255, 0.5);
  border-radius: 0 0 5px 5px;
  width: 100%;
  transition: width linear;
}

.close-button {
  background: none;
  border: none;
  color: rgb(141, 140, 140);
  font-size: 14px;
  cursor: pointer;
  margin-left: 10px;
  margin-bottom: 10px;
}

/* Progress bar color variations based on toast type */
.toast.success .progress-bar {
  background-color: rgb(21, 170, 18);
}

.toast.error .progress-bar {
  background-color: #e74c3c;
}

.toast.info .progress-bar {
  background-color: #95a5a6;
}

.toast.warning .progress-bar {
  background-color: #f39c12;
}

.toast.default .progress-bar {
  background-color: #3f51b5;
}

.toast.dark .progress-bar {
  background-color: #ffffff;
}

/* Toast container positions */
.toast-container {
  position: fixed;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.toast-container.top-right {
  top: 10px;
  right: 180px;
}

.toast-container.top-left {
  top: 10px;
  left: 180px;
}

.toast-container.top-center {
  top: 10px;
  left: 50%;
  transform: translateX(-50%);
}

.toast-container.bottom-left {
  bottom: 10px;
  left: 180px;
}

.toast-container.bottom-right {
  bottom: 10px;
  right: 180px;
}

.toast-container.bottom-center {
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
}

.toast-container.rtl {
  direction: rtl;
}
