.channelName{
    font-weight: 500;
    text-transform: capitalize;
    font-size: 14px;
    font-family: "Inter";
    color: #3968ed;
    cursor: pointer;
}
.btnSmall{
   font-size: 12px;
   padding: 5px 10px;
}
.paginationContainer{
  width: fit-content;
  margin: 10px auto 0 auto;
}