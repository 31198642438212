@import "../../Components/NewCustomComp/settings/globalMixins";
.white_wrapper {
    margin: 8px 0;
    background-color: #FFFFFF;
    padding: 10px 15px;
    border-radius: 5px;
}

.hotelName {
    max-width: 600px;
    margin: 10px auto;

    h3 {
        font-size: 20px;
        font-weight: 600;
        line-height: 35px;
    }
}

form {
    margin: 0 auto;
}


.targetSteps_wrapper {
    display: flex;
    margin: 8px auto 20px;
    gap: 17px;
    align-items: center;



    .targetSteps_card {
        display: inline-block;
        height: fit-content;
        padding: 10px 20px;
        background-color: #6889F1;
        color: #FFFFFF;
        font-size: 16px;
        position: relative;
        text-decoration: none;
        border-radius: 5px;
        cursor: pointer;

        &::before {
            content: "";
            position: absolute;
            z-index: 5;
            top: 6%;
            right: -35px;
            margin-top: 0px;
            border-width: 18px;
            border-style: solid;
            border-color: transparent transparent transparent #6889F1;
        }
    }

    .targetSteps_card_white {
        height: fit-content;
        display: inline-block;
        padding: 10px 20px;
        background-color: #FFFFFF;
        color: #000000;
        font-size: 16px;
        position: relative;
        text-decoration: none;
        border-radius: 5px;
        // border: 1px solid #CCC;
        cursor: pointer;


        &::before {
            content: "";
            position: absolute;
            z-index: 5;
            top: 6%;
            right: -35px;
            margin-top: 0px;
            border-width: 18px;
            border-style: solid;
            border-color: transparent transparent transparent #FFFFFF;
        }
    }


}

// >>>>>>>>>>>>>>>>>>>>>FirstForm
.firstFormWrapper {
    display: flex;
    justify-content: space-evenly;
    gap: 60px;
    margin-top: 15px;

    .months {
        width: 40%;

        .monthHead{
            display: flex;
            justify-content: end;
            gap: 20px;
            span{
                font-size: 9px;
            }
        }

        .firstFormCard {
            display: flex;
            gap: 15px;
            align-items: center;
            margin-bottom: 10px;

            span {
                width: 37%;
            }

           
        }
        .recommended_percent {
            padding: 6px 10px;
            margin-bottom: 17px;
            border-radius: 5px;
            border: 0.5px solid #CCCCCC;
            background-color: #ECF1FD;
        }
    }

}

// >>>>>>>>>>>>>>>>>>>>>SecondForm
.secondForm_wrapper {


    .secondForm_roomFlex {
        display: flex;
        justify-content: space-between;
        align-items: center;


        h1 {
            font-size: 18px;
            font-weight: 600;
            margin: 10px 0;
        }


    }

    .secondForm_expCard {
        display: flex;
        gap: 20px;
        align-items: flex-end;
        margin: 10px 0;
    }

    .secondForm_totalExp {
        margin-top: 10px;
    }
}

.secondForm_table {
    width: 100%;

    tr {
        .borderTop {
            border-top: 0.5px solid #CCCCCC;
        }

        .w_70 {
            width: 70%;
        }

        .w_25 {
            width: 25%;
            // text-align: right;
        }

        .w_5 {
            width: 5%;
            text-align: right;
        }

        th {

            text-align: left;
            padding: 8px 0;
        }

        td {
            padding: 8px 0;
        }
    }

    .totalExpanse {
        td {

            border: none;
            text-align: right;
            padding: 5px 0 0 0 !important;

            .resultInputs {
                width: 65px;
                height: 23px;
            }
        }
    }
}

.result_table {
    width: 100%;
    padding-right: 20px;

    tr {
        .w_72 {
            width: 72%;
            text-align: right;
            padding-right: 25px;
        }

        .w_25 {
            width: 25%;
            // text-align: right;
        }

        .w_5 {
            width: 5%;
            text-align: right;
        }

        td {
            font-weight: 500;
        }

        .td_gap {
            padding: 8px 25px 8px 0px;
        }
    }


}

.secondForm_result {
    display: flex;
    gap: 15px;

    .secondForm_resultWrapper {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin: 8px 0;

        .secondForm_revenue {
            display: flex;
            flex-direction: column;
            gap: 5px;
            margin: 0 !important;
        }
    }
}



// >>>>>>>>>>>>>>>>>>>ThirdForm
.thirdForm_wrapper {
    // display: flex;
    // gap: 10px;

    .firstForm_monthData {
        display: flex;
        flex-direction: column;
        gap: 5px;
    }

    .thirdForm_cards {
        h4 {
            font-size: 14px;
            font-weight: 600;
        }

        .secondForm_data {
            margin-top: 20px;
        }

        .secondForm_data,
        .firstForm_monthData {
            display: flex;
            flex-direction: column;
            gap: 5px;
        }
    }

    .thirdForm_sndResult {
        display: flex;
    }
}

.thirdForm_sndResult {
    display: flex;

    .thirdForm_sndResult_one {
        width: 35%;
    }
}

.crossBtn {
    cursor: pointer;
    background-color: #FFF;
    width: fit-content;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 3px;
    border-radius: 5px;
}

.overflowContainer{
    max-height: 100px;
    overflow-y: auto;
    @include scrollbar-styling
}