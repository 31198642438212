@import '../settings/_settings';

.status_tag {
    width: auto;
    font-family: $Inter;
    font-size: 12px;
    font-weight: 400;
    line-height: 15px;
    text-align: center;
    color: $color-white;
    border-radius: 10px;
    padding: 2px 6px;

    &.info {
        background-color: $primary-color;
    }

    &.success {
        background-color: $success-color;
    }

    &.warning {
        background-color: $warning-color;
    }

    &.critical {
        background-color: $critical-color;
    }

    &.danger {
        background-color: $danger-color;
    }

    &.draft {
        background-color: $draft-color;
    }
}

.primary_tag {
    // width: auto;
    font-family: $Inter;
    font-weight: 500;
    text-align: center;
    color: $color-white;
    border-radius: 10px;
    background-color: $primary-color;
    display: flex;
    align-items: center;

    &.small {
        font-size: 10px;
        line-height: 17px;
        padding: 0 4px;
    }

    &.medium {
        font-size: 12px;
        line-height: 17px;
        padding: 2px 6px;
    }

    &.large {
        font-size: 14px;
        line-height: 17px;
        padding: 4px 8px;
    }
}

.secondary_tag {
    // width: auto;
    font-family: $Inter;
    font-weight: 500;
    text-align: center;
    color: $ghost-btn-color;
    border-radius: 10px;
    padding: 2px 6px;
    background-color: $color-white;
    border: 1px solid $ghost-btn-active-color;
    display: flex;
    align-items: center;

    &.small {
        font-size: 10px;
        line-height: 17px;
    }

    &.medium {
        font-size: 12px;
        line-height: 17px;
    }

    &.large {
        font-size: 14px;
        line-height: 17px;
    }

    &:hover {
        background: transparent;
    }
}