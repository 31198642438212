.websiteMenusContainer {
  background-color: #eff0f2;
  width: 100%;
}
.websiteMenusSmallContainer {
  width: 100%;
  margin: 20px auto;
}
.websiteMenusHeading {
  color: #333;
  font-family: "Inter";
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  padding: 15px 0;
}
.websiteMenusText {
  color: #888;
  font-family: "Inter";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  width: 600px;
  margin: 10px 0 20px 0;
}
.websiteMenusItems {
  width: 100%;
  border-radius: 10px;
  background: #fff;
  padding: 20px;
}
.websiteMenusItemsTable {
  width: 100%;
  border-collapse: collapse;
  font-family: "Inter";
}
.websiteMenusItemsTableHeading {
  text-align: left;
  color: #333;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding: 10px 0;
}
.websiteMenusItemsTableButton {
  float: right;
  color: #3968ed;
  font-family: " Inter";
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  display: flex;
  height: 30px;
  padding: 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 4px;
  border: 1px solid #3968ed;
  cursor: pointer;
  background-color: transparent;
}

.websiteMenusItemsTableDataLinks {
  color: #3968ed;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-decoration-line: underline;
  padding: 10px 10px 10px 0;
  border-top: 0.5px solid #ccc;
  cursor: pointer;
}
.websiteMenusItemsTableData {
  color: #333;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding: 10px 0;
  border-top: 0.5px solid #ccc;
}
