.speedDialContainer {
  position: relative;
  width: fit-content;
  .fab {
    padding: 5px 10px;
    border-radius: 4px;
    background-color: #3968ed; // Blue color for the speed dial button
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.3);
    .icons {
      transform: rotate(0deg);
      transition: background-color 0.3s ease, box-shadow 0.3s ease,
        transform 0.3s ease;
      &.rotate {
        transform: rotate(45deg);
      }
    }
  }
  .actionsContainer {
    position: absolute;
    display: flex;
    gap: 5px;
    flex-direction: column;
    top: 35px;
    .actionButton{
      width: 35px;
      height: 35px ;
      background-color: #fff;
      border-radius: 4px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.3);
      border: 1px solid #ADADAD;
    }
  }
}
