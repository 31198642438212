.booking-summary {
    padding: 20px;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    background-color: #fff;
    width: 100%;
    margin: 0 auto;
  }
  
 
  
  .summary-cards {
    display: flex;
    justify-content: space-between;
  }
  
  .card {
    background-color: #3968ed;
    color: #fff;
    padding: 15px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    width: 24%;
    height: 90px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    display: flex;
    gap: 10px;
    align-items: center;
  }
  
  .icon {
    font-size: 24px;
    background-color: #8aa4ec;
    height: 33px;
    width: 33px;
    display: grid;
    place-items: center;
    border-radius: 6px;
  }
  
 
  
  .value {
    font-size: 18px;
    font-weight: bold;
    margin: 0;
  }
  
  .label {
    font-size: 14px;
    margin: 0;
  }
  
  .filter {
    display: flex;
    justify-content: flex-end;
    
    select {
      padding: 5px 10px;
      border: 1px solid #007bff;
      border-radius: 4px;
      background-color: #fff;
      color: #007bff;
      font-size: 14px;
      width: 150px;
      cursor: pointer;
      &:focus{
        outline: none;
      }
    }
  }
  
  
  