.selectPropertyContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;

  gap: 20px;
  .selectPropertyContainerChild {
    border: 2px solid #fff; //#aaaaaa
    box-shadow: 4px 4px 10px 0 rgba(0, 0, 0, 0.1),
      -4px -4px 10px 0 rgba(0, 0, 0, 0.1);
    width: 270px;
    height: 170px;
    gap: 0px;
    border-radius: 15px;
    background: linear-gradient(90deg, #3968ed 100%, #7c9cf3 100%); // #DDDDDD;
    cursor: pointer;
    background-color: red;
    .selectPropertySmallContainer {
      p {
        font-family: "Inter";
        font-size: 22px;
        font-weight: 600;
        line-height: 26.63px;
        text-align: center;
        color: #ffffff; //#888888
        margin-top: 5px;
      }
      .selectPropertyImgContainer {
        padding: 10px;
        background-color: #cfdbfb; // #cccccc
        border-radius: 7.5px;
        div {
          background-color: #ffffff; //#aaaaaa
          height: 46px;
          width: 46px;
          border-radius: 7.5px;
          svg {
            fill: #3968ed;
          }
        }
      }
    }
  }
  button{
    background-color: transparent;
    border: none;
  }

  .selectPropertyContainerChildDisabled {
    border: 2px solid #aaaaaa; //
    box-shadow: 4px 4px 10px 0 rgba(0, 0, 0, 0.1),
      -4px -4px 10px 0 rgba(0, 0, 0, 0.1);
    width: 270px;
    height: 170px;
    gap: 0px;
    border-radius: 15px;
    
    background: #dddddd;
    cursor: not-allowed;
    .selectPropertySmallContainer {
      p {
        font-family: "Inter";
        font-size: 22px;
        font-weight: 600;
        line-height: 26.63px;
        text-align: center;
        color: #888888; //
        margin-top: 5px;
      }
      .selectPropertyImgContainer {
        padding: 10px;
        background-color: #cccccc; //
        border-radius: 7.5px;
        div {
          background-color: #aaaaaa; //
          height: 46px;
          width: 46px;
          border-radius: 7.5px;
          svg {
            fill: #fff;
          }
        }
      }
    }
  }
}
