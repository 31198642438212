.night_audit_page_main {
  background-color: white;
  min-height: 100vh;
}

.night_audit_page_heading h2 {
  font-size: 18px;
  font-weight: 600;
}

.night_audit_top_step_form_child {
  display: flex;
  padding: 20px 0;
  border-bottom: 4px solid #cccccc;
  height: 95px;
  margin-bottom: 20px;
}

.night_audit_top_step_form_child .step_form_index {
  height: 30px;
  width: 30px;
  border: none;
  background: transparent;
  border-radius: 50%;
  color: #cccccc;
  border: 2px solid #cccccc;
}

.night_audit_top_step_form_child.selected {
  border-bottom: 4px solid #3968ed;
}

.night_audit_top_step_form_child.selected .step_form_index {
  background: #3968ed;
  border: 2px solid #3968ed;
  color: white;
}

.night_audit_top_step_form_child .step_form_content p {
  font-size: 14px;
  font-weight: 400;
}

.step_form_table_container {
  background-color: #eff0f2;
}

.hoteltable_container {
  width: 100%;
  margin: 0rem auto;
  overflow: auto;
  background: #eff0f2;
  position: relative;
}

.hoteltable_table,
.hoteltable_table_full {
  border-collapse: collapse;
  background-color: #eff0f2;
  border: 1px solid #ccc;
  border: none;
  width: 100%;
}

.hoteltable_table {
  width: 85%;
}

.hoteltable_table_full_folio {
  width: 100%;
  border-collapse: collapse;
  background-color: #eff0f2;

  display: block;
  height: 50%;
  overflow: auto;
}
.hoteltable_table_full_folio,
.hoteltable_table_full_folio tr {
  display: table;
  width: 100%;
  table-layout: fixed;
}

/* .hoteltable_table_full_folio,
.hoteltable_table_full_folio tr {
  display: table;
  width: 100%;
  table-layout: fixed; 
}
.hoteltable_table_full_folio,
.hoteltable_table_full_folio td {
  overflow: hidden;
}
.hoteltable_table_full_folio {
  width: calc(
    100% - 1em
  );
} */

.hoteltable_th {
  color: #666;
  text-align: left;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  padding: 10px 8px;
}

.hoteltable_tr {
  border-top: 1px solid rgb(219, 208, 208);
  cursor: pointer;
  height: 60px;
}

.hoteltable_td {
  text-align: left;
  color: #333;
  padding: 8px;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  overflow: hidden;
}

.hoteltable_status_container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 5px;
}

/* .skeleton-loading {
    animation: loading 1s infinite;
} */

@media screen and (max-width: 850px) {
  .hoteltable_container {
    width: 100%;
  }

  .hoteltable_table {
    width: 900px;
    z-index: -1;
  }

  ::-webkit-scrollbar {
    height: 5px;
  }

  .hoteltable_container::-webkit-scrollbar-thumb {
    background-color: #888;
    border-radius: 5px;
  }

  .hoteltable_container::-webkit-scrollbar-thumb:hover {
    background-color: #555;
  }

  .hoteltable_checkbox-column {
    position: sticky;
    left: 0;
    z-index: 1;
    background-color: #fff;
    border-right: 1px solid #ccc;
    width: 15%;
  }
}

/* Delete modal */
.bg-backdrop-nightAudit {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow-y: auto;
  z-index: 100 !important;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.4);
  display: flex;
  align-items: center;
  justify-content: center;

  backdrop-filter: blur(5px);
}
