.container {
  border-radius: 8px;
  padding: 15px;
  .bold {
    font-family: Inter;
    font-size: 14px;
    font-weight: 600;
  }
  .amountsText {
    font-family: Inter;
    font-size: 12px;
    font-weight: 400;
  }

  .heading {
    font-family: Inter;
    font-size: 16px;
    font-weight: 600;

    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
  }

  .details{
    font-family: Inter;
    font-size: 12px;
    font-weight: 400;
    color: #3968ed;
    cursor: pointer;
    &:hover{
        text-decoration: underline;
    }
    
  }

  .anchor{
    text-decoration: underline;
    cursor: pointer;
    color: #3968ed;
  }
}
