.createBookingTableComponentButton {
    border: none;
    background-color: transparent;
    cursor: pointer;
}

.createBookingTableComponentRow th {
    text-align: left;
    padding: 5px 0;
    border-bottom: 0.5px solid #ccc;
    font-family: "Inter";
    font-weight: 500;
    font-size: 12px;
}

.createBookingTableComponentRow td {
    text-align: left;
    padding: 5px 0;
    border-top: 0.5px solid #ccc;
    font-family: "Inter";
    font-weight: 400;
    font-size: 12px;
}

.createBookingTableComponentHeadingsContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.createBookingTableComponentContainer {
    background-color: #fff;
    width: 100%;
   
}

.createBookingTableComponentContainer table {
    width: 100%;
    border-collapse: collapse;
}

.createBookingTableNewPopupContainer {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 999;
    display: flex;
    justify-content: center;
    align-items: center;

}

.createBookingTableNewPopupContent {
    width: 700px;
    border-radius: 8px;
    background-color: #fff;
    padding: 20px;
}
.createBookingTableNewPopupContent h3{
    font-family: "Inter";
    font-size: 18px;
    font-weight: 600;
}
.createBookingTableNewPopupFooter{
    display: flex;
    gap: 20px;
    margin-top: 20px;
}

.createBookingTableNewPopupFooterBalanceCredit{
    margin: 10px 0;
}
.cancelled_add_on_payment{
    color: white;
    background-color: red;
    font-size: 12px;
    padding: 5px 10px;
    border-radius: 5px;
    cursor: pointer;
    border: none;

}