.recent-bookings {
  padding: 20px;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  background-color: #fff;
  width: 100%;
  margin: 0 auto;
  }
  
  .recent-bookings  {
   h2{
    font-size: 18px;
    margin-bottom: 10px;
    font-weight: bold;
   }
  }
  
  .booking-card {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px;
    border: 1px solid #ddd;
    border-radius: 8px;
    margin-bottom: 15px;
  }
  
  .booking-details {
    display: flex;
    flex-direction: column;
  }
  
  .booking-id {
    color: #007bff;
    text-decoration: none;
    font-weight: bold;
    margin-bottom: 5px;
  }
  
  .booking-platform {
    text-align: right;
  }
  
  .platform-link {
    color: #007bff;
    text-decoration: none;
    font-weight: bold;
  }
  
  .booking-amount {
    font-size: 18px;
    font-weight: bold;
    color: #333;
  }
  