.room_view_container,.meal_plan_box {
    display: flex;
    /* flex-wrap: wrap; */
    /* gap: 10px; */
    line-height: 20px;
    flex-direction: column;
    /* align-items: center; */
    /* justify-content: center; */
    border-radius: 4px;
    color: #fff;
    /* height: 85px; */
    padding: 10px;
    background-color: gray;
    cursor: pointer;
    position: relative;
    width: calc(15% - 10px);
    height: 85px;
    white-space: nowrap;
}
.room_status_popup{
    position: absolute;
    background-color: #fff;
    color: black;
    width: 100%;
    z-index: 99;
    left: 0;
    border-radius: 4px;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    bottom: -40px;
    display: flex;
    flex-direction: column;  
    border: 1px solid #333;
}
.room_status_popup button{
    padding: 5px 10px;
    border: 0;
    text-align: left;
    cursor: pointer;
}
.room_status_popup button:not(:last-of-type){
    border-bottom: 1px solid #333;
}

.room_view_container h5{
    color: #fff;
    font-size: 14px;
    font-weight: 600;
}
.room_view_container p{
    font-size: 12px;
   
}

.meal_plan_box h5{
    font-size: 20px;
    font-weight: 500;
}
/* .room_view_container{
    position: relative;
} */
.room_view_new_occupied_box_parent{
    position: relative;
}


.room_view_popup_container {
    position: absolute;
    background-color: white;
    border-radius: 4px;
    /* left: 35px; */
    /* bottom: 0; */
    width: 100%;
    top:85%;
    z-index: 99;
    box-shadow: 0 4px 4px 0px rgba(127, 155, 179, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0.10);
}
.room_view_popup_container p{
    border-bottom: 1px solid #ddd;
    padding: 5px;
    cursor: pointer;
    /* width: 125px; */
}
.meal_plan_main_container{
    position: relative;
    height: 60vh;
}

.meal_plan_view{
    position: absolute;
    bottom: 0;
    right: 0;
}
.meal-plan-container{
    position: relative;
    width: 149px;
}
.meal-options{
    position: absolute;
    z-index: 22;
    right: 5px;
    gap: 5px;
    top: 5px;
}
.meal-option{
    display: flex;
  
}
.meal-option p{
    background-color: white;
    padding: 0px 5px;
    border-radius: 2px;
}