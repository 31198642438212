.custom_booking_status {
    margin: 0 auto;
    display: flex;
    justify-content: center;
}

.booking_filter_title {
    font-size: 12px;
    color: rgba(136, 136, 136, 1);
    margin-bottom: 10px;
}

.booking_status_content {
    margin: 0 auto;
}

.booking_status_label {
    padding-left: 17px;
}

.checkbox_label {
    font-size: 12px;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 9px;
    padding: 3px 0px;
}

.checkbox_label input {
    width: 16px;
    height: 16px;
}