.flex-container {
  font-size: 9px;
  position: relative;
  width: fit-content;

  .hover-icon {
    position: absolute;
    left: 50%;
    top: -8px;
    transform: translateX(-50%);
    width: fit-content;
    cursor: pointer;
    opacity: 0;
    transition: opacity 0.3s;
  }
  .hover-icon-visible {
    position: absolute;
    left: 50%;
    top: -8px;
    transform: translateX(-50%);
    width: fit-content;
    cursor: pointer;
    transition: opacity 0.3s;
  }
  .tooltip {
    display: none;
    font-size: 10px;
    position: absolute;
    left: 50%;
    top: -35px;
    border-radius: 4px;
    transform: translateX(-50%);
    width: fit-content;
    cursor: pointer;
    transition: opacity 0.3s;
    width: max-content;
    background-color: #3968ed;
    color: #fff;
    padding: 5px;
  }
  .tooltip::after {
    content: "";
    position: absolute;
    left: 50%;
    top: 100%;
    transform: translateX(-50%);
    border-width: 6px;
    border-style: solid;
    border-color: #3968ed transparent transparent transparent; /* Arrow pointing down */
    z-index: 10;
  }
  .hover-icon-visible:hover .tooltip {
    display: block;
  }

  &:hover .hover-icon {
    opacity: 1;
  }
}
.tableInventory {
  text-align: left;
  border-spacing: 0 10px;
  th {
    background-color: rgb(239, 240, 242);
    padding: 10px;
    border-left: 1px solid #ccc;
  }
  th:first-child {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    border-left: none;
  }
  th:last-child {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
  }

  .longHeader {
    width: 30%;
    text-transform: capitalize;
  }
  .dateHeaders {
    width: 10%;
    span {
      font-family: Inter;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      color: #888888;
      text-align: center;
      display: flex;
      justify-content: center;
      background-color: #dddfe2;
      padding: 5px 0px;
      width: 80px;
      border-radius: 4px;
      cursor: pointer;
    }
  }
  .innerTableData {
    width: 10%;
    border-left: 1px solid #ccc;
    input {
      width: 80px;
      text-align: center;
    }
  }
  .innerTable {
    border: 1px solid #ccc;
    border-spacing: 0;
    border-radius: 4px;
    td {
      padding: 10px;
      button {
        border: none;
        background-color: transparent;
        cursor: pointer;
        color: #3968ed;
        text-transform: capitalize;
        font-weight: 500;
        &:hover {
          text-decoration: underline;
          transform: scale(1.01);
        }
      }
    }
    .innerTableBody {
      td {
        border-top: 1px solid #ccc;
      }
    }
  }
  .innerTableControls {
    text-align: right !important ;
    span {
      font-weight: 500;
      font-size: 12px;
      background-color: #bd941b;
      padding: 5px;
      border-radius: 8px;
      color: white;
      svg {
        cursor: pointer;
      }
    }
  }
}

.innerTableData {
  width: 10%;
  border-left: 1px solid #ccc;
  input {
    width: 80px;

    text-align: center;
  }
}
.innerTable {
  border: 1px solid #ccc;
  border-spacing: 0;
  border-radius: 4px;
  td {
    padding: 10px;
  }
  .innerTableBody {
    td {
      border-top: 1px solid #ccc;
    }
  }
}

.viewTypeContainer {
  display: flex;
  gap: 10px;
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 4px;
  .viewTypeButtons {
    background-color: transparent;
    border: none;
    cursor: pointer;
    text-wrap: nowrap;
    font-weight: 500;
    font-size: 14px;
    color: #888888;
    &:hover {
      color: #7796eb;
    }
  }
}

.InventoryRatesInnerTable {
  width: 100%;
  border: 1px solid #ccc;
  margin-top: 10px;

  .addBorderTop {
    border-top: 1px solid #ccc;
    padding: 25px 10px !important;
    .rateType {
      color: #333;
      font-size: 12px;
      font-weight: 600;
      letter-spacing: 0;
    }

    .inputContainer {
      position: relative;
      width: 100%;

      .inputRateChange {
        &:focus {
          outline: 1px solid #3968ed !important;
          border: none;
        }

        /* Select the button when the input is focused */
      }
      button {
        position: absolute;
        bottom: -18px;
      }
    }
  }
}

.channelNameText {
  text-transform: capitalize;
  font-weight: 600;
}

.stopSellTableData {
  text-align: right !important ;
  border-top: 1px solid #ccc;
  span {
    background-color: #bd941b;
    display: flex;
    align-items: center;
    gap: 3px;
    width: fit-content;
    float: right;
    cursor: pointer;
    padding: 5px;
    color: #fff;
    font-size: 12px;
    border-radius: 8px;
    font-weight: 400;
  }
}

.stopSellTableDataInputSmall {
  border-top: 1px solid #ccc;
  position: relative;
  padding: 20px 10px !important;
  span {
    display: grid;
    place-items: center;
    width: 100%;
    input{
           &:focus{
            border: 1px solid #3968ed !important;
            outline: none;
           }
    }
  }

  button {
    display: none;
    bottom: -15px;
    position: absolute;
  }

  &:focus-within {
    button {
      display: block;
    }
  }

}
