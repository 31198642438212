.folio_top_bar {
  width: 100%;
  background-color: #fff;
  padding: 14px 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.folio_summery_top_right {
  position: relative;
}

.folio_summery_top_right .show_feature_btn {
  border: 1px solid #3968ed;
  height: 30px;
  width: 30px;
  background: transparent;
  cursor: pointer;
}

.folio_summery .folio_summery_child {
  width: 33%;
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  min-height: 250px;
}

.folio_summery .folio_summery_child .folio_summery_child_top h3 {
  font-size: 16px;
  font-weight: 600;
}

.folio_summery_child_content {
  margin: 5px 0;
}

.folio_summery_child_content .small_text {
  font-style: italic;
  font-size: 12px;
}

.folio_summery_child_content .make_primary_btn {
  font-style: italic;
  font-size: 12px;
  border: none;
  background-color: transparent;
  color: #3968ed;
  cursor: pointer;
}

.folio_summery_child_content .delete_btn {
  border: none;
  background: transparent;
  margin-left: 10px;
}

.show_folio_feature_popup {
  background-color: #fff;
  border-radius: 2px;
  position: absolute;
  top: 0;
  padding: 5px 15px;
  right: 30px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  width: max-content;
  z-index: 1;
}

.show_folio_feature_popup button {
  display: block;
  border: none;
  background-color: transparent;
  margin: 5px 0;
  font-size: 12px;
  font-weight: 400;
  cursor: pointer;
}

.charges_main {
  background-color: #fff;
  border-radius: 8px;
}

.folio_top_bar_and_navigation_child .folio_top_title {
  font-size: 12px;
  line-height: 13px;
  font-size: "Inter";
  color: #333333;
  font-weight: 400;
}

.folio_top_bar_and_navigation_child .folio_top_bar_and_navigation_child_guests {
  font-size: 11px;
  font-size: "Inter";
  font-weight: 400;
  color: #333333;
  margin-left: 5px;
}

.folio_top_bar_and_navigation_child .folio_top_bar_and_navigation_child_dates {
  font-size: 14px;
  font-size: "Inter";
  font-weight: 400;
  color: #000000;
}

.folio_top_bar_and_navigation_child .folio_top_bar_and_navigation_child_dates span {
  font-size: 12px;
  font-size: "Inter";
  font-weight: 400;
  color: #000000;
}

.folio_top_bar_and_navigation_child {
  padding: 10px 20px;
}

.folio_top_bar_and_navigation_main {
  border-top: 0.5px solid #333333;
  border-bottom: 0.5px solid #333333;
  padding: 0;
}

.folio_top_nav {
  background-color: white;
  padding-left: 10px;
  padding-right: 20px;
}

.folio_top_nav_list {
  position: static;
}

.folio_total_amount_div {
  background-color: white;
  padding: 10px 0;
  /* bottom: 10px; */
  bottom: 0;
  position: fixed;
  width: 100%;
  z-index: 21;
}

.folio_total_amount_div .folio_amount_title {
  font-size: 12px;
  font-weight: 500;
}

.folio_total_amount_div .folio_amount_value {
  font-size: 16px;
  font-weight: 700;
}

.folio_total_amount_div .folio_bottom_grand_total_amount {
  color: #3968ed;
}

.folio_total_amount_div .folio_bottom_paid_amount {
  color: #15aa12;
}

.folio_total_amount_div .folio_bottom_due_amount {
  color: #bd941b;
}

.folio_guest_details_div {
  margin: 0 20px;
  padding-bottom: 70px;
}

.folio_guest_details_div .folio_guest_details_div_right {
  width: 70%;
}

.folio_guest_details_div_right .folio_guest_details_div_right_child {
  /* padding: 20px; */
  background-color: white;
  margin-bottom: 20px;
  border-radius: 6px;
}

.folio_guest_details_div_right_child .folio_guest_details_div_right_child_title {
  font-size: 16px;
  margin-bottom: 10px;
  font-weight: 600;
}

.folio_total_amount_div_sidebar {
  background-color: white;
  padding: 10px 0;
  bottom: 10px;
  display: flex;
  /* position: fixed; */
  /* width: 100%; */
}

.folio_total_amount_div .folio_amount_title {
  font-size: 12px;
  font-weight: 500;
}

.folio_total_amount_div .folio_amount_value {
  font-size: 16px;
  font-weight: 700;
}

.folio_total_amount_div_sidebar .folio_bottom_grand_total_amount {
  color: #3968ed;
}

.folio_total_amount_div_sidebar .folio_bottom_paid_amount {
  color: #15aa12;
}

.folio_total_amount_div_sidebar .folio_bottom_due_amount {
  color: #bd941b;
}

.folio_guest_details_div {
  margin: 0 20px;
  padding-bottom: 70px;
}

.folio_guest_details_div .folio_guest_details_div_right {
  width: 70%;
}

.folio_guest_details_div_right .folio_guest_details_div_right_child {
  /* padding: 20px; */
  background-color: white;
  margin-bottom: 20px;
  border-radius: 6px;
}

.folio_guest_details_div_right_child .folio_guest_details_div_right_child_title {
  font-size: 16px;
  margin-bottom: 10px;
  font-weight: 600;
}

/* gust details */

.active_room {
  gap: 5px;
  padding: 2px 4px;
  border: 1px solid #3968ED;
}

.active_room.active {
  background-color: #3969ed2d;
  font-weight: 600;
  color: #333333;
  border-radius: 6px;
}

.transfer_charges_table_parent {
  display: flex;
  justify-content: space-between;
  border-top: 1px solid #ddd;
  /* padding: 0px 20px; */
  width: 100%;
}

.transfer_charges_table1 {
  border-right: 1px solid #ddd;
  /* padding: 20px 34px 0px 0px; */
  position: relative;
  /* background-color: red; */
  padding: 20px;

  width: 50%;
}

.transfer_charges_table1_merge_folio {
  border-right: 1px solid #ddd;
  /* padding: 20px 34px 0px 0px; */
  position: relative;
  /* background-color: red; */
  padding: 15px;

  width: 50%;
}

.transfer_charges_table2 {
  /* padding: 20px 0px 0px 10px; */

  /* background-color: pink; */

  width: 50%;
  padding: 20px;
}

.transfer_charges_table_mid {
  position: absolute;
  top: 50%;
  left: 48%;
  cursor: pointer;
}


/* New Ui Folio */

.folio_room_wrapper{
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
}
.folio_room_wrapper .folio_rooms{
  width: 45%;
}

.folio_rooms {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 75px;
  border-radius: 6px;
}

.folio_room_guest {
  color: #333;
  font-size: 12px;
  font-weight: 400;
  text-align: center;
}

.folio_guest_details_wrapper{
  width: 15%;
}

.folio_guest_details{
  background-color: #FFF;
  border-radius: 6px;
  min-height: 250px;
  padding: 20px;
 
}
.folio_guest_details h3{
  font-size: 16px;
  color: #333;
  font-weight: 600;
}
.folio_add_button{
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  color: #3968ED;
  padding: 10px 0;
  width: 100%;
  border: 1px solid #3968ED;
  border-style: dashed;
}
.folio_add_guest_name{
  padding: 10px 0 10px 15px;
  border: 1px solid #3968ED;
  color: #333 !important;
}
.folio_guest_form{
  padding: 20px;
}
.folio_tax_amount_sm{
  font-size: 12px;
  color: #888;
}