.paymentsInclusionTable{
  width: 100%;

  .noDataFound{
    padding:5px;
    text-align: center;
    text-transform: lowercase;
    border-bottom: .5px solid #ddd;
  };
  .tableHeader{
   font-size: 12px;
   font-weight: 600;
   border-bottom: .5px solid #ddd;
   color: #000;
   padding: 5px;
  }
  .tableData{
    font-size: 12px;
    border-bottom: .5px solid #ddd;
    color: #333;
    padding: 5px;
  }
}