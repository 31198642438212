.orderListParentFAndBDashBoard {
  width: 100%;
}
.orderListLeftFAndBDashBoard {
  width: 70%;
  /* background-color: white; */
  border-radius: 4px;
}
.orderListRightFAndBDashBoard {
  width: 30%;
  background-color: #fff;
  /* height: 85vh; */
  position: relative;
}
.orderListFAndBDashBoard_table {
  width: 100%;
  border-collapse: collapse;
}
.orderListFAndBDashBoard_table thead {
  border-bottom: 1px solid #333;
  padding: 10px;
}
.orderListFAndBDashBoard_table td {
  padding: 10px;
}
.orderListFAndBDashBoard_table tr {
  height: 30px;
  padding: 10px;
}
.orderListFAndBDashBoard_table tbody tr:not(:first-of-type, :last-of-type) {
  border-bottom: 1px solid #333;
}
.orderListTableFooterFAndBDashBoard {
  position: absolute;
  right: 0;
  left: 0;
  bottom: 3px;
  background: rgba(221, 221, 221, 1);
  border-radius: 6px;
  width: 100%;
}
.orderListTableFooterIconFAndBDashBoard {
  background: #888888;
  border-radius: 50%;
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 50%;
  top: -13px;
  /* margin-top: 10px; */
}
/* element.style {
    background: #3711115e;
    border-radius: 50%;
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
} */
.orderListTableFooter_list {
  padding: 6px 10px;
  /* border-top: 0.5px solid #333; */
}
.orderListAmountFAndBDashboard {
  height: 27px;
  display: flex;
  align-items: center;
  width: 70px;
  outline: none;
  padding: 18px 2px;
  border: 0;
  border-radius: 4px;
}
