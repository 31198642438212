@import "../settings/_settings";

.primary_button {
  font-family: "Inter";
  display: flex;
  justify-content: center;
  align-items: center;
  color: $color-white;
  border-radius: 4px;
  font-weight: 400;
  font-family: "Inter";
  gap: 6px;
  font-style: normal;
  border-radius: 4px;
  background-color: $primary-btn-color;
  border: none;
  transition: 0.3s;
  cursor: pointer;
  box-sizing: border-box;

 

  &.small {
    font-size: 12px;
    line-height: 15px;
    padding: 4px 8px;
  }

  &.medium {
    font-size: 14px;
    line-height: 17px;
    padding: 6px 12px;
  }

  &.large {
    font-size: 16px;
    line-height: 19px;
    padding: 8px 16px;
  }

  &:not(:disabled):active {
    background-color: $primary-btn-active-color !important;
  }

  &:disabled {
    background-color: $primary-btn-disabled-color;
    color: #858585;
    cursor: not-allowed !important;
  }

  &:not(:disabled):hover  {
    background-color: $primary-btn-hover-color;
    
  }

  .bg_theme_button_spinner {
    border: 2px solid $color-white;
    border-left-color: $primary-btn-color;
    border-radius: 50%;
    width: 15px;
    height: 15px;
    animation: loaderThemeButton 0.7s linear infinite;

    &.small {
      height: 12px;
      width: 12px;
    }

    &.medium {
      height: 14px;
      width: 14px;
    }

    &.large {
      width: 16px;
      height: 16px;
    }
  }
}

.secondary_button {
  font-family: "Inter";
  display: flex;
  justify-content: center;
  align-items: center;
  color: $color-white;
  border-radius: 4px;
  font-weight: 400;
  font-family: "Inter";
  gap: 6px;
  font-style: normal;
  border-radius: 4px;
  background-color: transparent;
  border: 1px solid $primary-btn-color;
  transition: 0.3s;
  cursor: pointer;
  color: $primary-btn-color;
  box-sizing: border-box;


  &.small {
    font-size: 12px;
    line-height: 15px;
    padding: 3px 8px;
  }

  &.medium {
    font-size: 14px;
    line-height: 17px;
    padding: 5px 12px;
  }

  &.large {
    font-size: 16px;
    line-height: 19px;
    padding: 7px 16px;
  }

  &:not(:disabled):active {
    border: 1px solid $primary-btn-active-color !important;
    color: $primary-btn-active-color;
  }

  &:disabled {
    border: 1px solid $primary-btn-disabled-color;
    color: #adadad;
    cursor: not-allowed !important;
  }

  &:not(:disabled):hover {
    border: 1px solid $primary-btn-hover-color;
    color: $primary-btn-hover-color;

    // svg {
    //   path {
    //     fill: $primary-btn-hover-color;
    //   }
    // }
  }

  .bg_theme_button_spinner {
    border: 2px solid $primary-btn-color;
    border-left-color: $color-white;
    border-radius: 50%;
    width: 15px;
    height: 15px;
    animation: loaderThemeButton 0.7s linear infinite;

    &.small {
      height: 12px;
      width: 12px;
    }

    &.medium {
      height: 14px;
      width: 14px;
    }

    &.large {
      width: 16px;
      height: 16px;
    }
  }
}

.ghost_button {
  font-family: "Inter";
  display: flex;
  justify-content: center;
  align-items: center;
  color: $color-white;
  border-radius: 4px;
  font-weight: 400;
  font-family: "Inter";
  gap: 6px;
  font-style: normal;
  border-radius: 4px;
  background-color: transparent;
  border: none;
  transition: 0.3s;
  cursor: pointer;
  color: $ghost-btn-color;



  &.small {
    font-size: 12px;
    line-height: 15px;
    padding: 4px 8px;
  }

  &.medium {
    font-size: 14px;
    line-height: 17px;
    padding: 6px 12px;
  }

  &.large {
    font-size: 16px;
    line-height: 19px;
    padding: 8px 16px;
  }

  &:not(:disabled):active {
    background-color: $ghost-btn-active-color !important;
  }

  &:disabled {
    background-color: transparent;
    color: #adadad;
    cursor: not-allowed !important;
  }

  &:not(:disabled):hover {
    background-color: $ghost-btn-disabled-color;
    // color: $primary-btn-hover-color;
  }

  .bg_theme_button_spinner {
    border: 2px solid $ghost-btn-color;
    border-left-color: $ghost-btn-disabled-color;
    border-radius: 50%;
    width: 15px;
    height: 15px;
    animation: loaderThemeButton 0.7s linear infinite;

    &.small {
      height: 12px;
      width: 12px;
    }

    &.medium {
      height: 14px;
      width: 14px;
    }

    &.large {
      width: 16px;
      height: 16px;
    }
  }
}

.link_button {
  font-family: "Inter";
  display: flex;
  justify-content: center;
  align-items: center;
  color: $color-white;
  border-radius: 4px;
  font-weight: 400;
  font-family: "Inter";
  gap: 6px;
  font-style: normal;
  border-radius: 4px;
  background-color: transparent;
  border: none;
  transition: 0.3s;
  cursor: pointer;
  color: $primary-btn-color;

  &.small {
    font-size: 12px;
    line-height: 15px;
    // padding: 4px 8px;
  }

  &.medium {
    font-size: 14px;
    line-height: 17px;
    // padding: 6px 12px;
  }

  &.large {
    font-size: 16px;
    line-height: 19px;
    // padding: 8px 16px;
  }

  &:not(:disabled):active {
    // border: 1px solid $primary-btn-active-color !important;
    color: $primary-btn-active-color !important;

    svg {
      path {
        fill: $primary-btn-active-color;
      }
    }
  }

  &:disabled {
    color: $ghost-btn-active-color;
    cursor: not-allowed !important;
  }

  &:not(:disabled):hover {
    color: $primary-btn-hover-color;

    svg {
      path {
        fill: $primary-btn-hover-color;
      }
    }
  }

  .bg_theme_button_spinner {
    border: 2px solid $primary-btn-color;
    border-left-color: $color-white;
    border-radius: 50%;
    width: 15px;
    height: 15px;
    animation: loaderThemeButton 0.7s linear infinite;

    &.small {
      height: 12px;
      width: 12px;
    }

    &.medium {
      height: 14px;
      width: 14px;
    }

    &.large {
      width: 16px;
      height: 16px;
    }
  }
}

@keyframes loaderThemeButton {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

// ========== icon buttons========

.primary_icon_button {
  // background-color: $primary-color;
  border: none;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;

  &.large {
    height: 34px;
    width: 34px;
  }

  &.medium {
    height: 30px;
    width: 30px;
  }

  &.small {
    height: 24px;
    width: 24px;
  }

  &:hover {
    background-color: $button_disable;
  }
}

.secondary_icon_button {
  border: none;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #2b59d9;

  svg {
    fill: #2b59d9;
  }

  &.large {
    height: 34px;
    width: 34px;
  }

  &.medium {
    height: 30px;
    width: 30px;
  }

  &.small {
    height: 24px;
    width: 24px;
  }

  &:hover {
    border-color: #6a8ef1;

    svg {
      fill: #6a8ef1;
    }
  }
}

.primary_icon_button_rounded,
.secondary_icon_button_rounded {
  border-radius: 50% !important;
}

.ghost_icon_button {
  border: none;
  background-color: transparent;
  border-radius: 4px;

  &.large {
    height: 34px;
    width: 34px;
  }

  &.medium {
    height: 30px;
    width: 30px;
  }

  &.small {
    height: 24px;
    width: 24px;
  }

  &:hover {
    background-color: #d6d6d6;
  }
}


@keyframes loaderIconContainerRotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.loaderIconContainer {
  animation: loaderIconContainerRotate 1s linear infinite;
}
