@import '../settings/_settings';

.custom_checkbox_cont {
    padding: 15px 0;

    .custom_checkbox_title {
        font-family: $Inter;
        font-weight: 400;

        &.small {
            font-size: 12px;
            line-height: 15px;
        }

        &.medium {
            font-size: 14px;
            line-height: 17px;
        }

        &.large {
            font-size: 16px;
            line-height: 19px;
        }
    }

    .custom_checkbox_label {
        display: flex;
        align-items: center;
        cursor: pointer;
        font-family: $Inter;
        font-weight: 400;
        margin: 5px 0;
        position: relative; // Added for positioning

        input[type="checkbox"] {
            opacity: 0;
            position: absolute;

            &:checked+.custom_checkbox_box {
                background-color: $primary-btn-color;
                border-color: $primary-btn-color;

                &::after {
                    content: '';
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='white'%3E%3Cpath d='M20.285 2.285L9 13.571 3.715 8.285l-1.43 1.43L9 16.43 21.715 3.715z'/%3E%3C/svg%3E");
                    background-size: contain;
                    background-repeat: no-repeat;
                }
            }

            &:disabled+.custom_checkbox_box {
                border-color: $primary-btn-disabled-color;
                cursor: not-allowed !important;

                &::after {
                    border-color: $primary-btn-disabled-color;
                    cursor: not-allowed !important;
                }
            }
        }

        .custom_checkbox_box {
            border: 1px solid $draft-color;
            display: inline-block;
            margin-right: 8px;
            position: relative;
            transition: background-color 0.2s, border-color 0.2s;

            &.small {
                width: 12px;
                height: 12px;

                &::after {
                    width: 8px;
                    height: 8px;
                }
            }

            &.medium {
                width: 14px;
                height: 14px;

                &::after {
                    width: 10px;
                    height: 10px;
                }
            }

            &.large {
                width: 16px;
                height: 16px;

                &::after {
                    width: 12px;
                    height: 12px;
                }
            }
        }

        &.disabled_checkbox_input {
            border-color: $primary-btn-disabled-color;
            cursor: not-allowed !important;
            color: $ghost-btn-active-color;

            &::after {
                border-color: $primary-btn-disabled-color;
                cursor: not-allowed !important;
            }
        }

        &.small {
            font-size: 12px;
            line-height: 15px;
        }

        &.medium {
            font-size: 14px;
            line-height: 17px;
        }

        &.large {
            font-size: 16px;
            line-height: 19px;
        }
    }
}