.websiteMenuSingleItemContainer {
  width: 70%;
  margin: 20px auto;
}

.websiteMenuSingleItemTitleContainer {
  width: 100%;
  border-radius: 10px;
  background: #fff;
  margin-bottom: 10px;
}
.websiteMenuSingleItemHeading {
  color: #333;
  font-family: "Inter";
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 15px;
}
.websiteMenuSingleItemTitleInput {
  width: 100%;
  height: 30px;
  padding: 5px;
  border-radius: 4px;
  border: 0.5px solid #333;
  margin-top: 5px;
}
.websiteMenuSingleItemTitleInput:focus {
  outline: none;
}
.websiteMenuSingleItemTitleLabel {
  color: #333;
  font-family: "Inter";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.singleItemHeading {
  color: #333;
  font-family: "Inter";
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  padding: 20px;
}

.websiteMenuSingleItemSuggestions > li:hover {
  background-color: #eceaea;
  border-radius: 4px;
}
.websiteMenuSingleItemMenuItemsList {
  padding: 10px 20px;
  color: var(--333333, #333);
  font-family: "Inter";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.websiteMenuSingleItemSearchInputContainer {
  padding: 20px;
  height: fit-content;
}
.websiteMenuSingleItemMenuItemsListDragButton {
  background-color: transparent;
  border: none;
  margin-right: 10px;
  cursor: grab;
}
.websiteMenuSingleItemMenuItemsHr {
  height: 0.5px;
  background-color: #ccc;
}

.websiteMenuSingleItemMenuItemsList[draggable]:active {
  cursor: grabbing;
}
