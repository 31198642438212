.custom_search input[type="text"] {
    width: 100%;
    height: 40px;
    flex-shrink: 0;
    border-radius: 4px;
    border: 1px solid #ddd;
    background: #eff0f2;
    position: relative;
    color: #666;
    font-family: Inter;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    padding-left: 35px;
}

.custom_search {
    position: relative;
    max-width: 80%;
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.custom_search input:focus {
    border: none;
    outline: none;
}

.searchicon {
    position: absolute;
    top: 11px;
    left: 10px;
}

.suggestions {
    position: absolute;
    top: 40px;
    width: 100%;
    background-color: white;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    padding: 10px;

}

.single_suggestion {
    font-family: Inter;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    padding: 5px 5px;
}

.single_suggestion:hover {
    background-color: #e6e6e6;
    cursor: pointer;
}