.sales_insight {
  width: 100%;
  background-color: #fff;
  border-radius: 10px;
  padding: 20px;
  .radio_label {
    font-weight: normal;
    font-size: 12px;
  }
  .sales_info {
    width: 33%;
    height: 60px;
    border-radius: 6px;
    margin-top: 10px;


    &:first-of-type {
      background-color: #ecf1fd;
    }
    &:first-of-type {
      background-color: #ecf1fd; /* Light blue */
    }

    /* Second div */
    &:nth-of-type(2) {
      background-color: #fdecec; /* Light red */
    }

    /* Third div */
    &:nth-of-type(3) {
      background-color: #e7fde7; /* Light green */
    }

    /* Fourth div */
    &:nth-of-type(4) {
      background-color: #fdf5ec; /* Light orange */
    }
  }

  .inventory_info {
    width: 48%;
    border-radius: 6px;
    margin-top: 10px;
    padding: 10px 0;

    &:first-of-type {
      border: 1px solid #3968ed;
      color: #3968ed;
    }

    /* Second div */
    &:nth-of-type(2) {
      border: 1px solid #15aa12;
      color: #15aa12;
    }

    /* Third div */
    &:nth-of-type(3) {
      border: 1px solid #ff8040;
      color: #ff8040;
    }

    /* Fourth div */
    &:nth-of-type(4) {
      border: 1px solid #bd941b;
      color: #bd941b;
    }
  }
  .targets_info {
    background-color: #3968ed;
    color: #fff;
    width: 49%;
    margin-top: 5px;
    padding: 10px;
    border-radius: 0;
    &:nth-of-type(odd) {
      border-top-left-radius: 6px;
      border-bottom-left-radius: 6px;
    }

    /* Even ones: Rounded corners on the right side only */
    &:nth-of-type(even) {
      border-top-right-radius: 6px;
      border-bottom-right-radius: 6px;
    }
  }

  .amount {
    font-family: "Inter";
    font-size: 14px;
    font-weight: 600;
    line-height: 20.8px;
    text-align: left;
  }
  .label {
    font-family: Inter;
    font-size: 13px;
    font-weight: 400;
    line-height: 16.94px;
    text-align: left;
  }
}
.monthly_container {
  font-family: "Inter";
  font-size: 12px;
  font-weight: 400;
  line-height: 15px;
  text-align: left;
  color: #666666;
  border :1px solid #AAAAAA;
  padding :5px;
  border-radius: 4px;
}


.tooltip {
  position: absolute;
  bottom: 100%; // Position above the parent element
  left: 50%; // Center it horizontally
  transform: translateX(-50%); // Adjust for half its width
  background-color: inherit;
  color: #3968ed;
  padding: 5px;
  border-radius: 4px;
  z-index: 10;
  white-space: nowrap;
  width: auto; // Set to auto to fit the content
  margin-bottom: 5px; // Space between the tooltip and the item
  font-size: 12px;
}

