.container {
  button {
    background-color: transparent;
    border: none;
    color: #333;
    font-size: 14px;
    font-weight: 400;
    font-family: "Inter";
    cursor: pointer;
  }
}
.selectPropertyContainer {
  position: fixed;
  left: 0;
  top: 0;
  background-color: rgba(110, 110, 110, 0.6);
  backdrop-filter: blur(5px);
  width: 100%;
}
