@import "../../Components/NewCustomComp//settings/globalMixins.scss";

.discounts_container {
  background-color: #fff;
  //   height: 460px;
  border-radius: 8px;
  width: 100%;
  padding: 10px;
  overflow: auto;
  @include scrollbar-styling;

  h6 {
    font-family: "Inter";
    font-weight: 600;
    font-size: 16px;
    line-height: 16px;
    margin-bottom: 5px;
  }
}

.popupContainer {
  width: 700px;
  border-radius: 8px;
  background-color: white;
  padding: 10px;
  // box-shadow: #000000;
  h3 {
    font-size: 18px;
    font-weight: 600;
  }
}

.website_discount_container {
  input[type="checkbox"],
  input[type="radio"],
  label {
    cursor: pointer;
  }

  .table {
    // border: 1px solid #ccc;
    border-radius: 4px;
    width: 100%;
    border-spacing: 0;
    .headings {
      font-weight: 600;
      // border-bottom: 1px solid #ccc;
      // border-left: 1px solid #ccc;
      padding: 5px 5px;
      padding-top: 0;
      &:first-child {
        // border-bottom: 1px solid #ccc;
        border-left: 0;
      }
    }
    .content {
      padding: 5px 5px;
      padding-top: 0;
      // border-left: 1px solid #ccc;
      &:first-child {
        border: none;
      }
    }
  }
}
.rooms-selected{
  min-height: 20px;
  max-height: 40px;
  overflow: auto;
  @include scrollbar-styling;
}