
  .customSearchInputContainer {
    border: 1px solid #aaa6a6;
    border-radius: 4px;
    padding: 7px;
    width: 100%;
    
  }
  .customSearchInputContainerParentRef {
    border-radius: 4px;
    padding: 1.5px 1.5px;
  }
  .customSearchInputContainerParent {
    border-radius: 4px;
    padding: 1.5px 1.5px;
  }
  .custom-search-input-container-focused {
    outline: 3px solid #5682fa;
  }
  
  .customSearchInputCountryState {
    border: none;
    outline: none;
    width:100%;
    min-width: max-content;
  }
  
  .customSearchInputContainer {
    border: 1px solid #aaa6a6;
    border-radius: 4px;
    padding: 7px;
    background-color: #fff;
  }
  
  .custom-search-input-container-focused {
    outline: 3px solid #5682fa;
    border-radius: 4px;
  }
  
  .customSearchInputContainerParent {
    border-radius: 4px;
    padding: 1.5px 1.5px;
  }
  
  .customSearchSuggestionList {
    cursor: pointer;
  }
  
  .customSearchSuggestionList:hover {
    background-color: #e6e6e6;
  }
  
  .searchSuggestionButton {
    background-color: transparent;
    border: none;
    outline: none;
    width: 100%;
    text-align: left;
    cursor: pointer;
    padding: 5px;
  }
  
  .searchSuggestionButtonDisabled {
    cursor: not-allowed;
  }
  
  .customSearchLabelName {
    margin-bottom: 4px;
  }
  
  .customSearchSuggestion {
    position: absolute;
    top: 110%;
    left: 0;
    width: 100%;
    background-color: white;
    margin: 0;
    padding: 5px;
    max-height: 200px;
    list-style: none;
    overflow-y: auto;
    border-radius: 4px;
    box-shadow: 2px 2px 2px 2px rgba(0, 0, 0, 0.1);
    z-index: 1000;
  }
  .customSearchSuggestionListNoDataFound {
    padding: 5px 0;
  }
  .customSearchSuggestionListFocused{
    background-color: #e6e6e6;
    border-radius: 4px;
  }
  
  