.input_div_dashboard {
  border: 1px solid #AAAAAA;
  border-radius: 4px;
  /* margin: 0 auto; */
  width: 150px;
  display: flex;
  align-items: center;
  padding: 5px;
  
  cursor: pointer;
 

  position: relative;
}
.input_value_dashboard {
  border: 0;
  width: 100%;
  outline: none;
  cursor: pointer;
  color: #666666;
  font-size: 14px;

}
.popup_box_dashboard {
  border-radius: 4px;
  margin: 0 auto;
  border: 1px solid #CCC;
  position: absolute;
  top: 35px;
  left: 0;
  width: 100%;
  background-color: white;
  z-index: 9999999999;
  width: max-content;
  padding: 5px ;
}
.input_options_dashboard {
  display: flex;
  flex-direction: column;
  text-align: left;
  cursor: pointer;
  color: #666666;
  font-size: 12px;
  text-align: center;
  padding-top: 5px;
  width: max-content;
}
