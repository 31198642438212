.modifyReservationPopupTable{
    width: 100%;
    border-collapse: separate;
    border-spacing: 10px 5px;
}
.modifyReservationPopupTable th{
    text-align: left;
    font-family: "Inter";
    font-weight: "500";
}
.modify_reservation_reason_container{
    margin: 20px 0;
}
.modify_reservation_reason_container label{
    font-size: 12px;
    font-family: "Inter";
}