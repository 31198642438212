.booking_popup_btns {
  background-color: #3968ed;
  color: white;
  height: 35px;
  border: none;
  padding: 8px 20px;
  cursor: pointer;
  border-radius: 4px;
}

.booking_more_option_btn {
  cursor: pointer;
}

.close_popup {
  cursor: pointer;
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.close_popup:hover {
  background-color: rgb(199, 196, 196);
  border-radius: 50%;
}

.d_flex {
  display: flex;
}

.items_center {
  display: flex;
  align-items: center;
}

.common_padding {
  padding: 20px;
}

.booking_detail_popup_head {
  border-bottom: 1px solid #ddd;
  padding: 10px;
  justify-content: space-between;
}

.booking_id h3 {
  padding-right: 20px;
}

.booking_details_options_btns {
  border: 1px solid blue;
  padding: 8px 15px 8px 8px;
  background-color: #ffff;
  border-radius: 4px;
  color: blue;
  gap: 10px;
  height: 35px;
  cursor: pointer;
}

.booking_details_options {
  gap: 10px;
}

.booking_details_options_bottom {
  justify-content: space-between;
}

.booking_details_options_btns.print_btn,
.email_btn {
  position: relative;
}

.BgOutlineThemeButton {
  background-color: white !important;
}

.more_options_popup {
  position: absolute;
  background: white;
  position: absolute;
  bottom: 35px;
  left: 0px;
  width: max-content;

  box-shadow: blueviolet;
  text-align: left;
  z-index: 999;
  border-radius: 4px;
  box-shadow: 0 4px 4px 0px rgba(127, 155, 179, 0.1),
    0 6px 20px 0 rgba(0, 0, 0, 0.1);
}

.email_popup {
  position: absolute;
  background: white;
  position: absolute;
  color: black;
  top: 35px;
  left: 0px;
  width: max-content;

  box-shadow: blueviolet;
  text-align: left;
  z-index: 999;
  border-radius: 4px;
  box-shadow: 0 4px 4px 0px rgba(127, 155, 179, 0.1),
    0 6px 20px 0 rgba(0, 0, 0, 0.1);
}

.print_popup {
  position: absolute;
  background: white;
  color: black;
  position: absolute;
  top: 35px;
  left: 0px;
  width: max-content;

  box-shadow: blueviolet;
  text-align: left;
  z-index: 999;
  border-radius: 4px;
  box-shadow: 0 4px 4px 0px rgba(127, 155, 179, 0.1),
    0 6px 20px 0 rgba(0, 0, 0, 0.1);
}

.more_options_popup button {
  border: 0;
  background-color: transparent;
  border-bottom: 1px solid #dddd;
  padding: 5px;
  font-size: 14px;
  cursor: pointer;
}

.more_opt_popup {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: center;
}

.more_options_popup p {
  padding: 5px;
}

.more_options_popup p:not(:last-of-type) {
  border-bottom: 1px solid #ddd;
}

.print_popup p,
.email_popup p {
  padding: 5px 15px;
}

.print_popup p:not(:last-of-type),
.email_popup p:not(:last-of-type) {
  border-bottom: 1px solid #ddd;
}

.boking_details_popup.view_folio_btn {
  padding: 8px 30px;
}

.boking_details_popup_overview {
  background-color: #f5f5f5;
  text-align: left;
  padding: 10px 20px;
}

.boking_details_popup_overview h3 {
  font-weight: 600;
}

.booking_details {
  justify-content: space-between;
  text-align: left;
}

.booking_details .booking_details_col {
  width: 33.33%;
}

.booking_details_col > div {
  padding-bottom: 10px;
}

.booking_details_col h3 {
  font-weight: 600;
}

.booking_details_tax {
  border-top: 0.5px solid #777676;
  border-bottom: 0.5px solid #777676;
}

.booking_detail_popup_inclusion_list {
  border-bottom: 1px solid #dddd;
  justify-content: space-between;
  padding: 3px 0px;
}

.total_amt h3,
.total_amt p {
  color: blue;
}

.paid_amt h3,
.paid_amt p {
  color: #15aa12;
}

.due_amt h3,
.due_amt p {
  color: #bd941b;
}

.booking_popup_btns_bottom {
  padding: 5px 20px;
  margin: 10px;
}

.booking_more_option_btn {
  border: 1px solid blue;
  color: blue;
  margin: 10px;
  padding: 8px 20px;
  border-radius: 4px;
}

.booking_cancel_btn {
  border: 1px solid #e03838;
  color: #e03838;
  border-radius: 4px;
  height: 35px;
  padding: 8px 20px;
  cursor: pointer;
}

.booking_detail_bottom_btn {
  height: 35px;
}

.price_breakup {
  padding: 5px 20px;
  border-top: 1px solid #ddd;
  padding-bottom: 80px;
}

.common_btn {
  background-color: #0335fc;
  color: white;
  padding: 10px 16px;
  border: 0;
  border-radius: 4px;
}

#booking_details_slider_container_new {
  width: 50%;
  background: white;
  height: 100%;
  position: fixed;
  overflow: scroll;
  right: 0;
  top: 0;
  animation: slideIn 0.5s ease-in-out, changeBackgroundColor 1s ease;
  z-index: 999;
}

@keyframes slideIn {
  from {
    transform: translateX(100%);
  }

  to {
    transform: translateX(0);
  }
}

@keyframes changeBackgroundColor {
  0% {
    background-color: white;
  }

  80% {
    background-color: white;
  }

  /* 100% {
    background-color: rgba(0, 0, 0, 0.5);
  } */
}

.booking_details_options_bottom_container {
  position: fixed;
  bottom: 0;
  width: 40%;
  border-top: 1px solid #dddd;
  margin-top: 50px;
  background-color: #ffff;
  z-index: 20 !important;
}

.tooltipParagraphSlider {

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;

}