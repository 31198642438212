.custom_select_component_container {
  position: relative;
  width: 100%;
  font-family: Arial, sans-serif;
  padding: 0;
  font-family: "Inter";
  font-size: 14px;
  padding: 1px;
}

.custom_select_component_select_box {
  padding: 7px;
  border: 1px solid #ccc;
  border-radius: 4px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  
}

.custom_select_component_arrow {
  margin-left: 10px;
  display: inline-block;
  transition: transform 0.3s ease;
}

.custom_select_component_arrow.custom_select_component_open {
  transform: rotate(180deg);
}

.custom_select_component_options-list {
  position: absolute;
  top: 110%;
  left: 0;
  right: 0;
  border: 1px solid #ccc;
  background-color: #fff;
  z-index: 100000;
  max-height: 200px;
  overflow-y: auto;
  border-radius: 4px;
  margin: 0;
}

.custom_select_component_option {
  padding: 5px;
  cursor: pointer;
  font-size: 14px;
  font-family: "Inter";
}

.custom_select_component_option:hover {
  background-color: #f0f0f0;
}
.custom-search-input-container-focused {
  outline: 2px solid #5682fa;
  border-radius: 4px;
  
}
