.totalBookingsInvoicesMultipleGuests {
    width: 100%;
    height: 100%;
    overflow-y: auto;
    padding-bottom: 30px;
    margin: 0;
  }
  .totalBookingsInvoicesMultipleGuests::-webkit-scrollbar {
    width: 10px;
  }
  .totalBookingsInvoicesMultipleGuests::-webkit-scrollbar-thumb {
    width: 8px;
    height: 113.117px;
    border-radius: 50px;
    background: #ccc;
  }
  
  .totalBookingsInvoicesMultipleGuests::-webkit-scrollbar-track {
    background-color: #fff; /* Color of the track */
    border-left: 0.5px solid lightgray;
  }
  .totalBookingsCloseButtonHeadingContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    margin: 0;
  }
  .totalBookingsParaGraph {
    color: #888;
    text-align: center;
    font-family: "Inter";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin: 0;
    padding: 0;
  }
  .totalBookingsCloseButton {
    border: none;
    width: fit-content;
    height: fit-content;
    background-color: transparent;
    cursor: pointer;
  }
  .totalBookingsDateContainer {
    margin: 0;
    padding: 0;
  }
  .totalBookingsDate {
    color: #333;
    text-align: right;
    font-family: "Inter";
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin: 0;
    padding: 0;
  }
  .totalBookingsRoomType {
    color: #333;
    text-align: right;
    font-family: "Inter";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin: 0;
    padding: 0;
  }
  .totalBookingsTablesContainerForSingleGuest {
    padding: 10px;
  }
  .totalBookingsTableContainer {
    padding: 5px 0;
  }
  .totalBookingsTable {
    width: 100%;
  }
  
  .totalBookingsTableHeading {
    color: #333;
    font-family: "Inter";
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-align: left;
  }
  .totalBookingsTableData {
    color: #333;
    font-family: "Inter";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-align: left;
  }
  .totalBookingsTableDataInfo {
    color: #666;
    font-family: "Inter";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-align: right;
  }
  .totalBookingsHr {
    border: 0;
    height: 0.5px;
    background-color: #ccc;
  }
  
  .totalBookingsOverlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: flex-end;
    z-index: 1000;
    animation: fadeIn 0.5s ease-in-out;
    /* height: 100vh; */
  }
  
  @keyframes fadeIn {
    from {
      background: rgba(0, 0, 0, 0);
    }
    to {
      background: rgba(0, 0, 0, 0.5);
    }
  }
  
  .totalBookingsContainer {
    width: 40%;
    height: 100%;
    background: #fff;
    padding: 20px;
    box-shadow: -2px 0 5px rgba(0, 0, 0, 0.2);
    animation: slideIn 0.5s ease-in-out;
  }
  
  @keyframes slideIn {
    from {
      transform: translateX(100%);
    }
    to {
      transform: translateX(0);
    }
  }
  