@import "../settings/_settings";

.toggleSwitch {
    position: relative;
    display: inline-block;

    &.small,
    &.medium,
    &.large {
        .reactSwitchLabel {
            display: flex;
            align-items: center;
            justify-content: space-between;
            cursor: pointer;
            width: 100%;
            height: 100%;
            background: $ghost-btn-active-color;
            border-radius: 34px;
            position: relative;
            transition: background-color 0.2s;

            span {
                content: '';
                position: absolute;
                border-radius: 50%;
                transition: 0.2s;
                background: #F5F5F5;
                box-shadow: 0 0 2px 0 rgba(10, 10, 10, 0.29);
            }
        }
    }

    &.small {
        width: 23.14px;
        height: 12px;

        .reactSwitchLabel span {
            width: 9.33px;
            height: 9.33px;
            top: 1.33px;
            left: 1.33px;
        }
    }

    &.medium {
        width: 29.06px;
        height: 15px;

        .reactSwitchLabel span {
            width: 11.67px;
            height: 11.67px;
            top: 1.67px;
            left: 1.67px;
        }
    }

    &.large {
        width: 35px;
        height: 18px;

        .reactSwitchLabel span {
            width: 14px;
            height: 14px;
            top: 2px;
            left: 2px;
        }
    }

    .reactSwitchCheckbox {
        display: none;

        &:checked + .reactSwitchLabel {
            background: #3968ED;

            span {
                left: auto;
                right: 1.33px;
                transform: none;
            }
        }
    }

    .reactSwitchLabel:active span {
        width: 34px;
    }
}
