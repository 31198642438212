h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Inter";
}

.common_title h2 {
  color: #333;
  font-family: "Inter";
  font-size: 26px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.weight_700{
  font-weight: 700;
}

.h_100 {
  height: 100%;
}

.p_0 {
  padding: 0;
}

.p_20 {
  padding: 20px;
}

.p_top_0 {
  padding-top: 0px;
}

.theme_color {
  color: #3968ed;
}

.block {
  display: block;
}

.p_top_bottom_20 {
  padding: 20px 0;
}

.m_0_auto {
  margin: 0 auto;
}

.flex {
  display: flex;
}

.flex_direction_col {
  flex-direction: column;
}

.align_items_center {
  align-items: center;
}

.align_items_end {
  align-items: flex-end;
}
.align_items_baseline {
  align-items: baseline;
}

.text_center {
  text-align: center;
}

.text_right {
  text-align: right;
}

.m-0{
  margin: 0 !important;
}
.mb-5 {
  margin-bottom: 5px;
}

.mt-20 {
  margin-top: 20px;
}
.mb-5 {
  margin-top: 5px;
}
.w-100 {
  width: 100%;
}
.h-100 {
  height: 100%;
}
.w-5 {
  width: 5%;
}
.w-50 {
  width: 50%;
}
.w-33 {
  width: 33%;
}
.w-20 {
  width: 20%;
}
.w-25 {
  width: 25%;
}
.w-80{
  width: 80%;
}
.w-75{
  width: 75%;
}
.w-25 {
  width: 25%;
}
.w-90{
  width: 90%;
}

.w-5 {
  width: 5%;
}
.mt-5 {
  margin-top: 5px;
}
.pt-10 {
  padding-top: 10px;
}
.pt-15 {
  padding-top: 15px;
}
.pb-10 {
  padding-bottom: 10px;
}
.pb-15 {
  padding-bottom: 15px;
}
.pb-20 {
  padding-bottom: 20px;
}
.py-10{
  padding: 10px 0;
}

.mb-20 {
  margin-bottom: 20px;
}

.mt-10 {
  margin-top: 10px;
}
.mt-15 {
  margin-top: 15px;
}
.mb-10 {
  margin-bottom: 10px;
}
.mb-20 {
  margin-bottom: 20px;
}
.cursor-pointer {
  cursor: pointer;
}
.text-center {
  text-align: center;
}
.ml-10{
  margin-left: 10px;
}

.mb-10 {
  margin-bottom: 10px;
}

.w-full {
  width: 100%;
}

.w-half {
  width: 50%;
}

.w-70 {
  width: 70%;
}
.w-40{
 width: 40%;

}
.w-30{
  width: 30%;
}
.w-60{
  width: 60%;
  
 }

.w-fit-content {
  width: fit-content;
}

.mx-auto {
  margin: 0 auto;
}
.my-auto {
  margin: auto 0;
}

.w-30 {
  width: 30%;
}

.w-100 {
  width: 100%;
}

.w-33 {
  width: 33%;
}

.text-center {
  text-align: center;
}

.text-end {
  text-align: end;
}

.justify_content_center {
  justify-content: center;
}

.justify_content_end {
  justify-content: flex-end;
}

.justify_content_between {
  justify-content: space-between;
}

.flex_gap_5 {
  gap: 5px;
}
.flex_gap_10 {
  gap: 10px;
}

.flex_gap_15 {
  gap: 15px;
}

.flex_gap_20 {
  gap: 20px;
}

.flex_wrap {
  flex-wrap: wrap;
}

.border_none {
  border: none;
}

.bg_transparent {
  background: transparent;
}

.font_12 {
  font-size: 12px;
}

.icon_button_only {
  background-color: transparent;
  border: none;
  cursor: pointer;
}

.overflow_x_scroll_y_hidden {
  overflow-x: auto;
  overflow-y: hidden;
  cursor: pointer;
}

.overflow_y_scroll_x_hidden {
  overflow-x: hidden;
  overflow-y: auto;
  /* cursor: pointer; */
}

.overflow_x_scroll_y_hidden::-webkit-scrollbar {
  display: block;
  height: 5px;
}

.overflow_x_scroll_y_hidden::-webkit-scrollbar-track {
  background: rgb(204, 204, 204);
}

.overflow_x_scroll_y_hidden::-webkit-scrollbar-thumb {
  background-color: gray;
  border-right: none;
  border-left: none;
}

.overflow_y_scroll_x_hidden::-webkit-scrollbar {
  display: block;
  width: 5px;
}

.overflow_y_scroll_x_hidden::-webkit-scrollbar-track {
  background: rgb(204, 204, 204);
}

.overflow_y_scroll_x_hidden::-webkit-scrollbar-thumb {
  background-color: gray;
  border-right: none;
  border-left: none;
}

.pointer {
  cursor: pointer;
}
.not_allowed {
  cursor: not-allowed;
}

.flex-basis-10 {
  flex-basis: 10%;
}

.flex-basis-20 {
  flex-basis: 20%;
}

.flex-basis-30 {
  flex-basis: 30%;
}

.flex-basis-40 {
  flex-basis: 40%;
}

.flex-basis-50 {
  flex-basis: 50%;
}

.hoverCursor {
  cursor: pointer;
}
.bg-white {
  background-color: #fff;
}
.pt-5 {
  padding-top: 5px;
}

.pb-5 {
  padding-bottom: 5px;
}
.p-5 {
  padding: 5px;
}
.p-20 {
  padding: 20px;
}

.text-right{
  text-align: right;
}
/* .settlement_div_main div {
    padding: 0 10px;
} */
.text_align_center{
  text-align: center;
}
.pl-10{
padding-left: 10px;
}
.pl-15{
padding-left: 15px;
}
.pr-10{
  padding-right: 10px;
  }

.text-align-left{
  text-align: left;
}

.w-30 {
  width: 30%;
}
.br-8{
  border-radius: 8px;
}
.capitalize{
 text-transform: capitalize;
 
}

.border_top{
  border-top: 1px solid #ccc;
}
.border_bottom{
  border-bottom: 1px solid #ccc;
}
.border{
  border:  1px solid #ccc; ;
}
.p-15{
  padding: 15px;
}
.border_radius_8{
  border-radius: 8px;
}
.border_radius_4{
  border-radius: 4px;
}


.headings{
font-size: 18px;
font-weight: 600;
line-height: 21.78px;
text-align: left;

}